import { initialState, StateType } from "../state";
import Submission from "../../../domain/models/submission";

type StateSlice = StateType["submission"];

// Constants

const UPDATE_SELECTED_SUBMISSIONS = "submission/select";
const ADD_SELECTED_SUBMISSION = "submission/select/add";
const UPDATE_SELECTED_SUBMISSION = "submission/select/update";
const REMOVE_SELECTED_SUBMISSION = "submission/select/delete";
const UPDATE_EDIT_SUBMISSION = "submission/update";

// Selectors

export const selectedSubmissionsSelector = (state: StateType) =>
  state.submission?.selectedIds?.map((id) => state.submission.byId[id]);

export const editSubmissionSelector = (state: StateType) =>
  state.submission?.editSubmissionId
    ? state.submission.byId[state.submission.editSubmissionId]
    : null;

// Interfaces

export interface UpdateSelectedSubmissionsActionType {
  type: string;
  submissions: Submission[] | null;
}

export interface AddSelectedSubmissionActionType {
  type: string;
  submission: Submission | null;
}

export interface UpdateSelectedSubmissionActionType {
  type: string;
  submission: Submission | null;
}

export interface RemoveSelectedSubmissionActionType {
  type: string;
  id: number;
}

export interface UpdateEditSubmissionActionType {
  type: string;
  submission: Submission | null;
}

// Actions

export const updateSelectedSubmissionsAction = (
  submissions: Submission[]
): UpdateSelectedSubmissionsActionType => ({
  type: UPDATE_SELECTED_SUBMISSIONS,
  submissions,
});

export const addSelectedSubmissionAction = (
  submission: Submission
): AddSelectedSubmissionActionType => ({
  type: ADD_SELECTED_SUBMISSION,
  submission,
});

export const updateSelectedSubmissionAction = (
  submission: Submission
): UpdateSelectedSubmissionActionType => ({
  type: UPDATE_SELECTED_SUBMISSION,
  submission,
});

export const removeSelectedSubmissionAction = (
  id: number
): RemoveSelectedSubmissionActionType => ({
  type: REMOVE_SELECTED_SUBMISSION,
  id,
});

export const updateEditSubmissionAction = (
  submission: Submission
): UpdateEditSubmissionActionType => ({
  type: UPDATE_EDIT_SUBMISSION,
  submission,
});

// Handlers

const updateSelectedHandler = (
  state: StateSlice,
  action: UpdateSelectedSubmissionsActionType
): StateType["submission"] => {
  const submissions = action.submissions;
  if (submissions) {
    const byId = state?.byId ? { ...state.byId } : {};
    const selectedIds = submissions.map((o) => o.id);
    submissions.forEach((submission) => {
      byId[submission.id] = submission;
    });
    return { ...state, byId, selectedIds };
  }
  return { ...state, selectedIds: null };
};

const addSelectedItemHandler = (
  state: StateSlice,
  action: AddSelectedSubmissionActionType
): StateType["submission"] => {
  const submission = action.submission;
  const byId = state?.byId ? { ...state.byId } : {};
  const selectedIds = state?.selectedIds ? [...state.selectedIds] : [];
  selectedIds.unshift(submission.id);
  byId[submission.id] = submission;
  return { ...state, byId, selectedIds };
};

const updateSelectedItemHandler = (
  state: StateSlice,
  action: UpdateSelectedSubmissionActionType
): StateType["submission"] => {
  const submission = action.submission;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[submission.id]) {
    byId[submission.id] = submission;
  }
  return { ...state, byId };
};

const removeSelectedItemHandler = (
  state: StateSlice,
  action: RemoveSelectedSubmissionActionType
): StateType["submission"] => {
  const submissionId = action.id;
  const selectedIds = state?.selectedIds ? [...state.selectedIds] : [];
  const index = selectedIds.indexOf(submissionId);
  if (index >= 0) {
    selectedIds.splice(index, 1);
  }
  return { ...state, selectedIds };
};

const updateEditHandler = (
  state: StateSlice,
  action: UpdateEditSubmissionActionType
): StateType["submission"] => {
  if (action.submission) {
    const submission = action.submission;
    const byId = state?.byId ? { ...state.byId } : {};
    byId[submission.id] = submission;
    return { ...state, byId, editSubmissionId: submission.id };
  }
  return { ...state, editSubmissionId: null };
};

// Reducer

export const submissionReducer = (
  state: StateSlice = initialState.submission,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_SELECTED_SUBMISSIONS:
      return updateSelectedHandler(state, action);
    case ADD_SELECTED_SUBMISSION:
      return addSelectedItemHandler(state, action);
    case UPDATE_SELECTED_SUBMISSION:
      return updateSelectedItemHandler(state, action);
    case REMOVE_SELECTED_SUBMISSION:
      return removeSelectedItemHandler(state, action);
    case UPDATE_EDIT_SUBMISSION:
      return updateEditHandler(state, action);
    default:
      return state;
  }
};
