import Media from "./media";

class Config {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  login_background: Media;

  fromJson(data): Config {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.login_background && !Number.isInteger(data.login_background)) {
      this.login_background = new Media().fromJson(data.login_background);
    }
    return this;
  }
}

export default Config;
