import Project from "./project";
import ProjectRole from "./project-role";
import Submission from "./submission";

class Dashboard {
  projects_due_soon: Project[];
  project_recent: Project[];
  project_roles_recent: ProjectRole[];
  submissions_recent: Submission[];

  fromJson(data): Dashboard {
    if (data.projects_due_soon) {
      this.projects_due_soon = data.projects_due_soon.map((p) =>
        new Project().fromJson(p)
      );
    }
    if (data.project_recent) {
      this.project_recent = data.project_recent.map((p) =>
        new Project().fromJson(p)
      );
    }
    if (data.project_roles_recent) {
      this.project_roles_recent = data.project_roles_recent.map((p) =>
        new ProjectRole().fromJson(p)
      );
    }
    if (data.submissions_recent) {
      this.submissions_recent = data.submissions_recent.map((p) =>
        new Submission().fromJson(p)
      );
    }
    return this;
  }
}

export default Dashboard;
