import { logError } from "../../../utils/log";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { updatePropertyListsAction } from "../actions/property-list-action";
import { PropertyListService } from "../../../data/services/data-property-list-service";
import { ManagePropertyListInteractor } from "../../../domain/usecases/manage-property-list-interactor";

// Constants

export const FETCH_ALL_PROPERTY_LISTS = "propertyList/saga/fetchAll";

// Interfaces

interface FetchAllPropertyListsActionType {
  type: string;
}

// Actions

export const fetchAllPropertyListsAction =
  (): FetchAllPropertyListsActionType => ({
    type: FETCH_ALL_PROPERTY_LISTS,
  });

// Sagas

function* fetchAllPropertyListsSaga(action: FetchAllPropertyListsActionType) {
  try {
    const propertyListService = new PropertyListService();
    const interactor = new ManagePropertyListInteractor(propertyListService);

    const propertyLists = yield interactor.getPropertyLists();
    yield put(updatePropertyListsAction(propertyLists));
  } catch (err) {
    yield put(logError(err, "Property Lists"));
  }
}

// Combined Sagas

export default [
  takeLatest(FETCH_ALL_PROPERTY_LISTS, fetchAllPropertyListsSaga),
];
