import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from '../styles/theme';
import Head from 'next/head';
import { signIn } from 'next-auth/client';
import Link from 'next/link';
import Menu from '../components/menu';
import { useDispatch, useSelector } from 'react-redux';
import { ClientClientBioInteractor } from '../../domain/usecases/client-client-bio-interactor';
import { ClientService } from '../../data/services/data-client-service';
import { logError } from '../../utils/log';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const MenuContainer = styled.div`
  width: 20vw;
  background-color: #2b333e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  width: 80vw;
  background-color: #f1f4f9;
  min-height: 100vh;
  padding-bottom: 100px;
`;

function ClientLayout(props) {
  const { children, session, title } = props;
  // console.log("ClientLayout session", session);
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (session?.error == 'RefreshAccessTokenError') {
      console.log('API error:', session?.error);
      setTimeout(() => {
        // signIn('cognito', { callbackUrl: '/' });
        window.location.href = '/logout';
      }, 2000);
    } else if (session?.error == 'APIAccessTokenError') {
      console.log('API error:', session?.error);
      setTimeout(() => {
        // signIn('cognito', { callbackUrl: '/' });
        window.location.href = '/logout';
      }, 2000);
    } else if (session?.user?.role == 'client' && session?.client_id) {
      const clientClientBioInteractor = new ClientClientBioInteractor(
        session?.jwt,
        new ClientService()
      );
      clientClientBioInteractor
        .getClientForClient(session.client_id)
        .then((client) => {
          setClient(client);
        })
        .catch((err) => {
          dispatch(logError(err, 'Client Layout'));
        });
    }
  }, []);

  return (
    <>
      <Head>
        <title>{title || 'Virtual Vox'}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <BodyContainer>
        <MenuContainer>
          <Menu
            session={session}
            draftProjectsCount={0}
            castingProjectsCount={0}
            pendingProjectsCount={0}
            openProjectsCount={0}
            submittedProjectsCount={0}
            projectRolesWithPendingSubmissionsCount={0}
            client={client}
          />
        </MenuContainer>
        <ContentContainer>{React.cloneElement(children, { ...props })}</ContentContainer>
      </BodyContainer>
    </>
  );
}

export default ClientLayout;
