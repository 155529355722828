import { ProjectServiceType } from '../services/project-service';
import Project from '../models/project';

export class ManageProjectInteractor {
  jwt: string;
  projectService: ProjectServiceType;

  constructor(jwt: string, projectService: ProjectServiceType) {
    this.jwt = jwt;
    this.projectService = projectService;
  }

  async getProjects(start?: number): Promise<Project[]> {
    return this.projectService.getProjects(this.jwt, start);
  }

  async getProject(id: number): Promise<Project> {
    return this.projectService.getProject(this.jwt, id);
  }

  async createProject(data: any): Promise<Project> {
    return this.projectService.createProject(this.jwt, data);
  }

  async updateProject(id: number, data: any): Promise<Project> {
    return this.projectService.updateProject(this.jwt, id, data);
  }

  async updateProjectStatus(id: number, status: string): Promise<Project> {
    return this.projectService.updateProjectStatus(this.jwt, id, status);
  }

  async deleteProject(id: number): Promise<void> {
    return this.projectService.deleteProject(this.jwt, id);
  }
}
