import { PropertyListServiceType } from "../services/property-list-service";
import PropertyList from "../models/property-list";

export class ManagePropertyListInteractor {
  propertyListService: PropertyListServiceType;

  constructor(propertyListService: PropertyListServiceType) {
    this.propertyListService = propertyListService;
  }

  async getPropertyLists(): Promise<PropertyList[]> {
    return this.propertyListService.getPropertyLists();
  }
}
