import { SubmissionServiceType } from '../../domain/services/submission-service';
import fetch from 'isomorphic-unfetch';
import Submission from '../../domain/models/submission';
import { parseJsonRes } from '../../utils/parse-res';

export class SubmissionService implements SubmissionServiceType {
  async getSubmissions(jwt: string, projectRoleId: number): Promise<Submission[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/submissions?project_role=${projectRoleId}&_sort=created_at:DESC&_limit=2000`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const submissions = resData.map((o) => new Submission().fromJson(o));
    return submissions;
  }

  async getSubmission(submissionId: number): Promise<Submission> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/submissions/${submissionId}`);
    const resData = await parseJsonRes(res);
    const submission = new Submission().fromJson(resData);
    return submission;
  }

  async createSubmission(data: any): Promise<Submission> {
    console.log('createSubmission', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_media) {
      const file = data.new_media;
      formData.append(`files.media`, file, file.name);
    }
    if (data.new_nda) {
      const file = data.new_nda;
      formData.append(`files.nda`, file, file.name);
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/submissions`, {
      method: 'POST',
      body: formData
    });
    const resSubmissionData = await parseJsonRes(res);
    if (res.ok) {
      const resDuration = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/submissions/${resSubmissionData.id}/duration`,
        {
          method: 'PUT'
        }
      );
      const resData = await parseJsonRes(resDuration);
      return new Submission().fromJson(resData);
    }
  }

  async updateSubmission(jwt: string, submissionId: number, data: any): Promise<Submission> {
    console.log('updateSubmission', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_media) {
      const file = data.new_media;
      formData.append(`files.media`, file, file.name);
    }
    if (data.new_nda) {
      const file = data.new_nda;
      formData.append(`files.nda`, file, file.name);
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/submissions/${submissionId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resSubmissionData = await parseJsonRes(res);
    const resDuration = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/submissions/${submissionId}/duration`,
      {
        method: 'PUT'
      }
    );
    const resData = await parseJsonRes(resDuration);
    return new Submission().fromJson(resData);
  }

  async duplicateSubmission(jwt: string, submissionId: number): Promise<Submission> {
    const submission = await this.getSubmission(submissionId);
    submission.id = null;
    submission.created_at = new Date();
    submission.updated_at = submission.created_at;
    const newSubmission = await this.createSubmission(submission);
    return newSubmission;
  }

  async trimSubmission(
    jwt: string,
    submissionId: number,
    start: number,
    end: number
  ): Promise<Submission> {
    console.log('trimSubmission', start, end);
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/submissions/${submissionId}/trim?start=${start}&end=${end}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    return new Submission().fromJson(resData);
  }

  async deleteSubmission(jwt: string, submissionId: number): Promise<Submission> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/submissions/${submissionId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    return new Submission().fromJson(resData);
  }
}
