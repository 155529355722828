export async function parseJsonRes(res: Response) {
  if (res.ok) {
    return await res.json();
  }
  if (res.status == 404) {
    throw new Error("No record found");
  }
  const resData = await res.json();
  if (resData?.message) {
    throw new Error(resData.message);
  } else {
    throw new Error("An error occurred.");
  }
}
