import { ProjectRoleServiceType } from '../../domain/services/project-role-service';
import fetch from 'isomorphic-unfetch';
import ProjectRole from '../../domain/models/project-role';
import { parseJsonRes } from '../../utils/parse-res';

export class ProjectRoleService implements ProjectRoleServiceType {
  async getProjectRole(jwt: string, id: number): Promise<ProjectRole> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/project-roles/${id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    return new ProjectRole().fromJson(resData);
  }

  async createProjectRole(jwt: string, data: any): Promise<ProjectRole> {
    console.log('createProjectRole', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_attachments) {
      for (let i = 0; i < data.new_attachments.length; i++) {
        const file = data.new_attachments[i];
        formData.append(`files.attachments`, file, file.name);
      }
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/project-roles`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new ProjectRole().fromJson(resData);
  }

  async updateProjectRole(jwt: string, id: number, data: any): Promise<ProjectRole> {
    console.log('updateProjectRole', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_attachments) {
      for (let i = 0; i < data.new_attachments.length; i++) {
        const file = data.new_attachments[i];
        formData.append(`files.attachments`, file, file.name);
      }
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/project-roles/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new ProjectRole().fromJson(resData);
  }

  async getClientOpenProjectRoles(jwt: string, clientId: number): Promise<ProjectRole[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/project-roles/client/${clientId}/open`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const projectRoles = resData.map((o) => new ProjectRole().fromJson(o));
    return projectRoles;
  }

  async getClientProjectRolesWithSubmissions(
    jwt: string,
    clientId: number
  ): Promise<ProjectRole[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/project-roles/client/${clientId}/withsubmissions`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const projectRoles = resData.map((o) => new ProjectRole().fromJson(o));
    return projectRoles;
  }

  async searchProjectRoles(jwt: string, txt: string): Promise<ProjectRole[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/project-roles?title_contains=${txt}&_sort=title:ASC&_limit=20`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const projectRoles = resData.map((o) => new ProjectRole().fromJson(o));
    return projectRoles;
  }

  async deleteProjectRole(jwt: string, id: number): Promise<void> {
    console.log('deleteProjectRole', id);
    const formData = new FormData();
    await fetch(`${process.env.NEXT_PUBLIC_API_URL}/project-roles/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    return;
  }
}
