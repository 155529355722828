import Client from './client';
import ProjectRole from './project-role';

class Invitation {
  id: number;
  status: string;
  project_role: ProjectRole;
  created_at: Date;
  updated_at: Date;
  client: Client;
  client_id: number;
  unique: string;
  is_overscale: boolean;
  is_requested: boolean;

  fromJson(data): Invitation {
    this.id = data.id;
    this.status = data.status;
    if (data.project_role && !Number.isInteger(data.project_role)) {
      this.project_role = new ProjectRole().fromJson(data.project_role);
    }
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.client) {
      if (Number.isInteger(data.client)) {
        this.client_id = data.client;
      } else {
        this.client = new Client().fromJson(data.client);
        this.client_id = data.client.id;
      }
    }
    this.unique = data.unique;
    this.is_overscale = data.is_overscale;
    this.is_requested = data.is_requested;
    return this;
  }
}

export default Invitation;
