import Media from './media';
import Property from './property';

class Client {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  properties: Property[];
  email: string;
  email_cc: string;
  author: number;
  slug: string;
  description: string;
  media_demo: Media[];
  media_profile: Media;
  unique: string;
  video_demo: Media;
  first_name: string;
  last_name: string;
  documents_valid: boolean;
  employment_type?: 'individual' | 'corporation';
  user: number | null;

  fromJson(data): Client {
    this.id = data.id;
    this.name = data.name;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.properties) {
      this.properties = data.properties.map((p) => new Property().fromJson(p));
    }
    this.email = data.email;
    this.email_cc = data.email_cc;
    this.author = data.author;
    this.slug = data.slug;
    this.description = data.description;
    if (data.media_demo) {
      this.media_demo = data.media_demo
        .map((m) => new Media().fromJson(m))
        .sort((a, b) => {
          const aDate = new Date(a.created_at);
          const bDate = new Date(b.created_at);
          return bDate.getTime() - aDate.getTime();
        });
    }
    if (data.media_profile) {
      this.media_profile = new Media().fromJson(data.media_profile);
    }
    this.unique = data.unique;
    if (data.video_demo) {
      this.video_demo = new Media().fromJson(data.video_demo);
    }
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.documents_valid = data.documents_valid;
    this.employment_type = data.employment_type;
    this.user = data.user;
    return this;
  }
}

export default Client;
