import * as Sentry from '@sentry/nextjs';
import { showSnackbarErrorAction } from '../adapters/redux';

export function logError(error: Error, category: string) {
  console.log('logError', process.env.NEXT_PUBLIC_ENV, category, error.message, error.name);
  if (process.env.NEXT_PUBLIC_ENV === 'production') {
    console.error(error);
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
  return showSnackbarErrorAction(
    `Error in ${category}: ${error?.message || 'the incident has been logged.'}.`
  );
}
