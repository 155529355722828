import { SubmissionServiceType } from "../services/submission-service";
import Submission from "../models/submission";

export class ManageSubmissionInteractor {
  jwt: string;
  submissionService: SubmissionServiceType;

  constructor(jwt: string, submissionService: SubmissionServiceType) {
    this.jwt = jwt;
    this.submissionService = submissionService;
  }

  async createSubmission(data: any): Promise<Submission> {
    return this.submissionService.createSubmission(data);
  }

  async updateSubmission(submissionId: number, data: any): Promise<Submission> {
    return this.submissionService.updateSubmission(
      this.jwt,
      submissionId,
      data
    );
  }

  async duplicateSubmission(submissionId: number): Promise<Submission> {
    return this.submissionService.duplicateSubmission(this.jwt, submissionId);
  }

  async trimSubmission(
    submissionId: number,
    start: number,
    end: number
  ): Promise<Submission> {
    return this.submissionService.trimSubmission(
      this.jwt,
      submissionId,
      start,
      end
    );
  }

  async deleteSubmission(submissionId: number): Promise<Submission> {
    return this.submissionService.deleteSubmission(this.jwt, submissionId);
  }
}
