import { initialState, StateType } from "../state";
import Config from "../../../domain/models/config";

type StateSlice = StateType["config"];

// Constants

const UPDATE_CONFIG = "config/update";

// Selectors

export const configSelector = (state: StateType) => state.config?.config;

// Interfaces

export interface UpdateConfigActionType {
  type: string;
  config: Config | null;
}

// Actions

export const updateConfigAction = (config: Config): UpdateConfigActionType => ({
  type: UPDATE_CONFIG,
  config,
});

// Handlers

const updateHandler = (
  state: StateSlice,
  action: UpdateConfigActionType
): StateType["config"] => {
  return { ...state, config: action.config };
};

// Reducer

export const configReducer = (
  state: StateSlice = initialState.config,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_CONFIG:
      return updateHandler(state, action);
    default:
      return state;
  }
};
