import { all, call, put, takeLatest } from "redux-saga/effects";
import BuyerSagas from "./sagas/buyer-saga";
import ClientSagas from "./sagas/client-saga";
import ConfigSagas from "./sagas/config-saga";
import InvitationSagas from "./sagas/invitation-saga";
import ProjectRoleSagas from "./sagas/project-role-saga";
import ProjectSagas from "./sagas/project-saga";
import PropertyListSagas from "./sagas/property-list-saga";
import SubmissionSagas from "./sagas/submission-saga";

export function* rootSaga() {
  yield all([
    ...BuyerSagas,
    ...ClientSagas,
    ...ConfigSagas,
    ...InvitationSagas,
    ...ProjectSagas,
    ...ProjectRoleSagas,
    ...PropertyListSagas,
    ...SubmissionSagas,
  ]);
}
