import Buyer from './buyer';
import Media from './media';
import ProjectRole from './project-role';
import User from './user';

class Project {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  status: string;
  due: Date;
  description: string;
  notes: string;
  buyer: Buyer;
  attachments: Media[];
  project_roles: ProjectRole[];
  nda_required: boolean;
  author: number;
  agents: User[];
  slating_required: boolean;
  media_file_format: string;
  package_url: string;
  video_required: boolean;

  fromJson(data): Project {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.status = data.status;
    this.due = data.due;
    this.description = data.description;
    this.notes = data.notes;
    if (data.buyer && !Number.isInteger(data.buyer)) {
      this.buyer = new Buyer().fromJson(data.buyer);
    }
    if (data.attachments) {
      this.attachments = data.attachments.map((a) => new Media().fromJson(a));
    }
    if (data.project_roles) {
      this.project_roles = data.project_roles.map((a) => new ProjectRole().fromJson(a));
    }
    this.nda_required = data.nda_required;
    this.author = data.author;
    if (data.agents) {
      this.agents = data.agents.map((u) => new User().fromJson(u));
    }
    this.slating_required = data.slating_required;
    this.media_file_format = data.media_file_format;
    this.package_url = data.package_url;
    this.video_required = data.video_required;
    return this;
  }

  get projectTotalInvitationsUnsent(): number {
    let count = 0;
    if (this.project_roles && this.project_roles.length > 0) {
      this.project_roles.forEach((r) => {
        if (r.total_invitations_unsent > 0) {
          count += r.total_invitations_unsent;
        }
      });
    }
    return count;
  }
}

export default Project;
