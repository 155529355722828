import React, { useEffect, useState, useContext } from "react";
import type { AppProps /*, AppContext */ } from 'next/app'
import "../node_modules/normalize.css/normalize.css";
// import "../presentation/styles/fonts.css";
import "../presentation/styles/globals.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
import "react-datepicker/dist/react-datepicker.css";
import LayoutWrapper from "../presentation/layouts/layout-wrapper";
import PlayerProvider from "../state/player-context";
import { playerContext } from "../state/player-context";
import Player from "../presentation/components/player";
import { wrapper } from '../frameworks/redux'
import { Provider as NextAuthProvider } from 'next-auth/client'
import SuccessSnackbar from "../presentation/components/snackbar-success";
import ErrorSnackbar from "../presentation/components/snackbar-error";
import InfoSnackbar from "../presentation/components/snackbar-info";
import * as Sentry from "@sentry/nextjs";

const PlayerWrapper = () => {
  const playerState = useContext(playerContext);
  const { currentTrack, nextTrack } = playerState;
  return currentTrack ? (
    <Player
      onStopTrack={() => {
        nextTrack();
      }}
    />
  ) : (
    <></>
  );
};

function MyApp({ Component, pageProps }: AppProps) {

  useEffect(() => {
    const session = pageProps?.session
    if (session?.id && session?.user?.email && session?.user?.name) {
      // console.log("_app Sentry User", {
      //   id: session.id.toString(),
      //   email: session.user.email,
      //   name: session.user.name,
      // });
      Sentry.setUser({
        id: session.id.toString(),
        email: session.user.email,
        name: session.user.name,
      });
    }
  }, [pageProps?.session])

  return (
    <NextAuthProvider session={pageProps.session}>
      <PlayerProvider>
        <InfoSnackbar />
        <SuccessSnackbar />
        <ErrorSnackbar />
        <LayoutWrapper {...pageProps}>
          <Component {...pageProps} />
        </LayoutWrapper>
        <PlayerWrapper />
      </PlayerProvider>
    </NextAuthProvider>
  );
}

export default wrapper.withRedux(MyApp);
