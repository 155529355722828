import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware, { Task } from "redux-saga";
import { createWrapper } from "next-redux-wrapper";
import {
  rootSaga,
  buyerReducer,
  clientReducer,
  configReducer,
  invitationReducer,
  platformReducer,
  projectReducer,
  projectRoleReducer,
  propertyListReducer,
  submissionReducer,
} from "../adapters/redux";

const rootReducer = {
  buyer: buyerReducer,
  client: clientReducer,
  config: configReducer,
  invitation: invitationReducer,
  platform: platformReducer,
  projectRole: projectRoleReducer,
  project: projectReducer,
  propertyList: propertyListReducer,
  submission: submissionReducer,
};

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export interface SagaStore extends Store {
  sagaTask?: Task;
}

export const makeStore = (context) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    combineReducers(rootReducer),
    bindMiddleware([sagaMiddleware])
  );

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });

// const middleware = [];
// const sagaMiddleware = createSagaMiddleware();

// middleware.push(sagaMiddleware);
// if (process.env.NODE_ENV !== "production") {
//   middleware.push(createLogger());
// }

// const store = createStore(
//   combineReducers(rootReducer),
//   applyMiddleware(...middleware)
// );

// sagaMiddleware.run(rootSaga);
// export default store;
