import { ProjectRoleServiceType } from "../services/project-role-service";
import { InvitationServiceType } from "../services/invitation-service";
import { SubmissionServiceType } from "../services/submission-service";
import ProjectRole from "../models/project-role";
import Invitation from "../models/invitation";
import Submission from "../models/submission";

type SelectTasksType = [
  projectRole: Promise<ProjectRole>,
  invitations: Promise<Invitation[]>,
  submissions: Promise<Submission[]>
];

export interface SelectedProjectRoleType {
  projectRole: ProjectRole;
  invitations: Invitation[];
  submissions: Submission[];
}

class SelectedProjectRole implements SelectedProjectRoleType {
  projectRole: ProjectRole;
  invitations: Invitation[];
  submissions: Submission[];
  constructor(
    projectRole: ProjectRole,
    invitations: Invitation[],
    submissions: Submission[]
  ) {
    this.projectRole = projectRole;
    this.invitations = invitations;
    this.submissions = submissions;
  }
}

export class SelectProjectRoleInteractor {
  jwt: string;
  projectRoleService: ProjectRoleServiceType;
  invitationService: InvitationServiceType;
  submissionService: SubmissionServiceType;

  constructor(
    jwt: string,
    projectRoleService: ProjectRoleServiceType,
    invitationService: InvitationServiceType,
    submissionService: SubmissionServiceType
  ) {
    this.jwt = jwt;
    this.projectRoleService = projectRoleService;
    this.invitationService = invitationService;
    this.submissionService = submissionService;
  }

  async getSelected(projectRoleId: number): Promise<SelectedProjectRoleType> {
    const tasks: SelectTasksType = [
      this.projectRoleService.getProjectRole(this.jwt, projectRoleId),
      this.invitationService.getInvitations(this.jwt, projectRoleId),
      this.submissionService.getSubmissions(this.jwt, projectRoleId),
    ];
    const result = await Promise.all(tasks);
    return new SelectedProjectRole(result[0], result[1], result[2]);
  }
}
