import { logError } from "../../../utils/log";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { updateConfigAction } from "../actions/config-action";
import { ConfigService } from "../../../data/services/data-config-service";
import { LandingInteractor } from "../../../domain/usecases/landing-interactor";

// Constants

export const FETCH_CONFIG = "config/saga/fetch";

// Interfaces

interface FetchConfigActionType {
  type: string;
}

// Actions

export const fetchConfigAction = (): FetchConfigActionType => ({
  type: FETCH_CONFIG,
});

// Sagas

function* fetchConfigSaga(action: FetchConfigActionType) {
  try {
    const configService = new ConfigService();
    const interactor = new LandingInteractor(configService);

    const config = yield interactor.getConfig();
    yield put(updateConfigAction(config));
  } catch (err) {
    yield put(logError(err, "Config"));
  }
}

// Combined Sagas

export default [takeLatest(FETCH_CONFIG, fetchConfigSaga)];
