import { initialState, StateType } from "../state";

type StateSlice = StateType["platform"];

// Constants

const SNACKBAR_CLEAR = "platform/snackbar_clear";
const SNACKBAR_SUCCESS = "platform/snackbar_success";
const SNACKBAR_ERROR = "platform/snackbar_error";
const SNACKBAR_INFO = "platform/snackbar_info";

// Selectors

export const snackbarSelector = (state: StateType) => state.platform?.snackbar;

// Interfaces

export interface ClearSnackbarActionType {
  type: string;
}

export interface ShowSuccessSnackbarActionType {
  type: string;
  message: string;
}

export interface ShowErrorSnackbarActionType {
  type: string;
  message: string;
}

export interface ShowInfoSnackbarActionType {
  type: string;
  message: string;
}

// Actions

export const clearSnackbarAction = (): ClearSnackbarActionType => ({
  type: SNACKBAR_CLEAR,
});

export const showSnackbarSuccessAction = (
  message: string
): ShowSuccessSnackbarActionType => ({
  type: SNACKBAR_SUCCESS,
  message,
});

export const showSnackbarErrorAction = (
  message: string
): ShowErrorSnackbarActionType => ({
  type: SNACKBAR_ERROR,
  message,
});

export const showSnackbarInfoAction = (
  message: string
): ShowInfoSnackbarActionType => ({
  type: SNACKBAR_INFO,
  message,
});

// Reducer

export const platformReducer = (
  state: StateSlice = initialState.platform,
  action: any
): StateSlice => {
  switch (action.type) {
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbar: {
          successSnackbarOpen: false,
          successSnackbarMessage: "",
          errorSnackbarOpen: false,
          errorSnackbarMessage: "",
          infoSnackbarOpen: false,
          infoSnackbarMessage: "",
        },
      };
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        snackbar: {
          successSnackbarOpen: true,
          successSnackbarMessage: action.message,
          errorSnackbarOpen: false,
          errorSnackbarMessage: "",
          infoSnackbarOpen: false,
          infoSnackbarMessage: "",
        },
      };
    case SNACKBAR_ERROR:
      return {
        ...state,
        snackbar: {
          successSnackbarOpen: false,
          successSnackbarMessage: "",
          errorSnackbarOpen: true,
          errorSnackbarMessage: action.message,
          infoSnackbarOpen: false,
          infoSnackbarMessage: "",
        },
      };
    case SNACKBAR_INFO:
      return {
        ...state,
        snackbar: {
          successSnackbarOpen: false,
          successSnackbarMessage: "",
          errorSnackbarOpen: false,
          errorSnackbarMessage: "",
          infoSnackbarOpen: true,
          infoSnackbarMessage: action.message,
        },
      };
    default:
      return state;
  }
};
