import { logError } from '../../../utils/log';
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
  updateClientsAction,
  addClientAction,
  updateClientAction,
  updateSelectedClientAction,
  removeClientAction
} from '../actions/client-action';
import { ClientService } from '../../../data/services/data-client-service';
import { ManageClientInteractor } from '../../../domain/usecases/manage-client-interactor';

// Constants

export const FETCH_ALL_CLIENTS = 'client/saga/fetchAll';
export const SELECT_CLIENT = 'client/saga/select';
export const NEW_CLIENT = 'client/saga/new';
export const SAVE_CLIENT = 'client/saga/save';
export const DELETE_CLIENT = 'client/saga/delete';

// Interfaces

interface FetchAllClientsActionType {
  type: string;
  jwt: string;
}

interface SelectClientActionType {
  type: string;
  jwt: string;
  id: number;
}

interface NewClientActionType {
  type: string;
  jwt: string;
  data: any;
  completed: (id: number) => void;
}

interface SaveClientActionType {
  type: string;
  jwt: string;
  id: number;
  data: any;
  completed: () => void;
}

interface DeleteClientActionType {
  type: string;
  jwt: string;
  id: number;
  completed: () => void;
}

// Actions

export const fetchAllClientsAction = (jwt: string): FetchAllClientsActionType => ({
  type: FETCH_ALL_CLIENTS,
  jwt
});

export const selectClientAction = (jwt: string, id: number): SelectClientActionType => ({
  type: SELECT_CLIENT,
  jwt,
  id
});

export const newClientAction = (
  jwt: string,
  data: any,
  completed?: (id: number) => void
): NewClientActionType => ({
  type: NEW_CLIENT,
  jwt,
  data,
  completed
});

export const saveClientAction = (
  jwt: string,
  id: number,
  data: any,
  completed?: () => void
): SaveClientActionType => ({
  type: SAVE_CLIENT,
  jwt,
  id,
  data,
  completed
});

export const deleteClientAction = (
  jwt: string,
  id: number,
  completed?: () => void
): DeleteClientActionType => ({
  type: DELETE_CLIENT,
  jwt,
  id,
  completed
});

// Sagas

function* fetchAllClientsSaga(action: FetchAllClientsActionType) {
  try {
    const service = new ClientService();
    const interactor = new ManageClientInteractor(action.jwt, service);

    const clients = yield interactor.getClients();
    yield put(updateClientsAction(clients));
  } catch (err) {
    yield put(logError(err, 'Clients'));
  }
}

function* selectClientSaga(action: SelectClientActionType) {
  const { id } = action;
  try {
    const service = new ClientService();
    const interactor = new ManageClientInteractor(action.jwt, service);

    yield put(updateSelectedClientAction(null));

    const client = yield interactor.getClient(id);
    yield put(updateSelectedClientAction(client));
  } catch (err) {
    yield put(logError(err, 'Clients'));
  }
}

function* newClientSaga(action: NewClientActionType) {
  const { data, completed } = action;
  try {
    const service = new ClientService();
    const interactor = new ManageClientInteractor(action.jwt, service);

    const client = yield interactor.createClient(data);
    yield put(addClientAction(client));
    if (completed) {
      completed(client.id);
    }
  } catch (err) {
    yield put(logError(err, 'Clients'));
  }
}

function* saveClientSaga(action: SaveClientActionType) {
  const { id, data, completed } = action;
  try {
    const service = new ClientService();
    const interactor = new ManageClientInteractor(action.jwt, service);

    const client = yield interactor.updateClient(id, data);
    yield put(updateClientAction(client));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Clients'));
  }
}

function* deleteClientSaga(action: DeleteClientActionType) {
  const { id, completed } = action;
  try {
    const service = new ClientService();
    const interactor = new ManageClientInteractor(action.jwt, service);

    const client = yield interactor.deleteClient(id);
    yield put(removeClientAction(client));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Clients'));
  }
}

// Combined Sagas

export default [
  takeLatest(FETCH_ALL_CLIENTS, fetchAllClientsSaga),
  takeLatest(SELECT_CLIENT, selectClientSaga),
  takeEvery(NEW_CLIENT, newClientSaga),
  takeEvery(SAVE_CLIENT, saveClientSaga),
  takeEvery(DELETE_CLIENT, deleteClientSaga)
];
