import React from "react";
import styled from "styled-components";
import Head from "next/head";
import { getSession, signIn } from "next-auth/client";
import Link from "next/link";

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

function AnonymousLayout(props) {
  const { children, session } = props;
  // console.log("IndexPage session", session);

  return (
    <>
      <Head>
        <title>Virtual Vox</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <BodyContainer>{React.cloneElement(children, { ...props })}</BodyContainer>
    </>
  );
}

export const getServerSideProps = async ({ req }) => {
  const session = await getSession({ req });
  return {
    props: {
      session,
    },
  };
};

export default AnonymousLayout;
