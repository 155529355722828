import { ProjectRoleServiceType } from '../services/project-role-service';
import ProjectRole from '../models/project-role';

export class ManageProjectRoleInteractor {
  jwt: string;
  projectRoleService: ProjectRoleServiceType;

  constructor(jwt: string, projectRoleService: ProjectRoleServiceType) {
    this.jwt = jwt;
    this.projectRoleService = projectRoleService;
  }

  async getProjectRole(id: number): Promise<ProjectRole> {
    return this.projectRoleService.getProjectRole(this.jwt, id);
  }

  async createProjectRole(data: any): Promise<ProjectRole> {
    return this.projectRoleService.createProjectRole(this.jwt, data);
  }

  async updateProjectRole(id: number, data: any): Promise<ProjectRole> {
    return this.projectRoleService.updateProjectRole(this.jwt, id, data);
  }

  async deleteProjectRole(id: number): Promise<void> {
    return this.projectRoleService.deleteProjectRole(this.jwt, id);
  }
}
