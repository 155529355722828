import { initialState, StateType } from "../state";
import Invitation from "../../../domain/models/invitation";

type StateSlice = StateType["invitation"];

// Constants

const UPDATE_SELECTED_INVITATIONS = "invitation/select";
const ADD_SELECTED_INVITATION = "invitation/select/add";
const UPDATE_SELECTED_INVITATION = "invitation/select/update";
const REMOVE_SELECTED_INVITATION = "invitation/select/delete";

// Selectors

export const selectedInvitationsSelector = (state: StateType) =>
  state.invitation?.selectedIds?.map((id) => state.invitation.byId[id]);

// Interfaces

export interface AddSelectedInvitationActionType {
  type: string;
  invitation: Invitation | null;
}

export interface UpdateSelectedInvitationsActionType {
  type: string;
  invitations: Invitation[] | null;
}

export interface UpdateSelectedInvitationActionType {
  type: string;
  invitation: Invitation | null;
}

export interface RemoveSelectedInvitationActionType {
  type: string;
  id: number;
}

// Actions

export const updateSelectedInvitationsAction = (
  invitations: Invitation[]
): UpdateSelectedInvitationsActionType => ({
  type: UPDATE_SELECTED_INVITATIONS,
  invitations,
});

export const addSelectedInvitationAction = (
  invitation: Invitation
): AddSelectedInvitationActionType => ({
  type: ADD_SELECTED_INVITATION,
  invitation,
});

export const updateSelectedInvitationAction = (
  invitation: Invitation
): UpdateSelectedInvitationActionType => ({
  type: UPDATE_SELECTED_INVITATION,
  invitation,
});

export const removeSelectedInvitationAction = (
  id: number
): RemoveSelectedInvitationActionType => ({
  type: REMOVE_SELECTED_INVITATION,
  id,
});

// Handlers

const updateSelectedHandler = (
  state: StateSlice,
  action: UpdateSelectedInvitationsActionType
): StateType["invitation"] => {
  const invitations = action.invitations;
  if (invitations) {
    const byId = state?.byId ? { ...state.byId } : {};
    const selectedIds = invitations.map((o) => o.id);
    invitations.forEach((invitation) => {
      byId[invitation.id] = invitation;
    });
    return { ...state, byId, selectedIds };
  }
  return { ...state, selectedIds: null };
};

const addSelectedItemHandler = (
  state: StateSlice,
  action: AddSelectedInvitationActionType
): StateType["invitation"] => {
  const invitation = action.invitation;
  const byId = state?.byId ? { ...state.byId } : {};
  const selectedIds = state?.selectedIds ? [...state.selectedIds] : [];
  selectedIds.unshift(invitation.id);
  byId[invitation.id] = invitation;
  return { ...state, byId, selectedIds };
};

const updateSelectedItemHandler = (
  state: StateSlice,
  action: UpdateSelectedInvitationActionType
): StateType["invitation"] => {
  const invitation = action.invitation;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[invitation.id]) {
    byId[invitation.id] = invitation;
  }
  return { ...state, byId };
};

const removeSelectedItemHandler = (
  state: StateSlice,
  action: RemoveSelectedInvitationActionType
): StateType["invitation"] => {
  const invitationId = action.id;
  const selectedIds = state?.selectedIds ? [...state.selectedIds] : [];
  const index = selectedIds.indexOf(invitationId);
  if (index >= 0) {
    selectedIds.splice(index, 1);
  }
  return { ...state, selectedIds };
};

// Reducer

export const invitationReducer = (
  state: StateSlice = initialState.invitation,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_SELECTED_INVITATIONS:
      return updateSelectedHandler(state, action);
    case ADD_SELECTED_INVITATION:
      return addSelectedItemHandler(state, action);
    case UPDATE_SELECTED_INVITATION:
      return updateSelectedItemHandler(state, action);
    case REMOVE_SELECTED_INVITATION:
      return removeSelectedItemHandler(state, action);
    default:
      return state;
  }
};
