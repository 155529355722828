class Property {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  priority: number;
  property_list: number;

  fromJson(data): Property {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.priority = data.priority;
    this.property_list = data.property_list;
    return this;
  }
}

export default Property;
