import { ConfigServiceType } from "../../domain/services/config-service";
import fetch from "isomorphic-unfetch";
import Config from "../../domain/models/config";
import Dashboard from "../../domain/models/dashboard";
import { parseJsonRes } from "../../utils/parse-res";

export class ConfigService implements ConfigServiceType {
  async getConfig(): Promise<Config> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/config`);
    const resData = await parseJsonRes(res);
    const config = new Config().fromJson(resData);
    return config;
  }

  async requestAccess(jwt: string, userId: number): Promise<void> {
    const data = { userId };
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/config/request-access`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      return;
    }
    throw new Error("Request Access failed.");
  }

  async getDashboard(jwt: string): Promise<Dashboard> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/config/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    const resData = await parseJsonRes(res);
    const dashboard = new Dashboard().fromJson(resData);
    return dashboard;
  }
}
