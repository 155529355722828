import { BuyerServiceType } from '../../domain/services/buyer-service';
import fetch from 'isomorphic-unfetch';
import Buyer from '../../domain/models/buyer';
import Project from '../../domain/models/project';
import { parseJsonRes } from '../../utils/parse-res';

const PAGE_COUNT = 200;

export class BuyerService implements BuyerServiceType {
  async searchBuyers(jwt: string, txt: string, start: number, limit?: number): Promise<Buyer[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/buyers?title_contains=${txt}&_sort=title:ASC&_limit=${
        limit ? limit : start ? start : 0 + PAGE_COUNT
      }&_start=${start ? start : 0}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const buyers = resData.map((o) => new Buyer().fromJson(o));
    return buyers;
  }

  async getBuyerByProject(jwt: string, projectId: number): Promise<Buyer> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/projects/${projectId}?_sort=title:ASC`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const project = new Project().fromJson(resData);
    return project.buyer;
  }

  async getBuyers(jwt: string): Promise<Buyer[]> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/buyers?_sort=title:ASC`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const buyers = resData.map((o) => new Buyer().fromJson(o));
    return buyers;
  }

  async getBuyer(jwt: string, buyerId: number): Promise<Buyer> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/buyers/${buyerId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const buyer = new Buyer().fromJson(resData);
    return buyer;
  }

  async createBuyer(jwt: string, data: any): Promise<Buyer> {
    console.log('createBuyer', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_attachments) {
      for (let i = 0; i < data.new_attachments.length; i++) {
        const file = data.new_attachments[i];
        formData.append(`files.attachments`, file, file.name);
      }
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/buyers`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Buyer().fromJson(resData);
  }

  async updateBuyer(jwt: string, buyerId: number, data: any): Promise<Buyer> {
    console.log('updateBuyer', buyerId, data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_attachments) {
      for (let i = 0; i < data.new_attachments.length; i++) {
        const file = data.new_attachments[i];
        formData.append(`files.attachments`, file, file.name);
      }
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/buyers/${buyerId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Buyer().fromJson(resData);
  }
}
