import { ConfigServiceType } from "../services/config-service";
import Config from "../models/config";

export class LandingInteractor {
  configService: ConfigServiceType;

  constructor(configService: ConfigServiceType) {
    this.configService = configService;
  }

  async getConfig(): Promise<Config> {
    return this.configService.getConfig();
  }
}
