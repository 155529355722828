import React, { useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from '../styles/theme';
import Head from 'next/head';
import { signIn } from 'next-auth/client';
import Link from 'next/link';
import Menu from '../components/menu';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllProjectsAction,
  draftProjectsSelector,
  castingProjectsSelector,
  pendingProjectsSelector,
  openProjectsSelector,
  submittedProjectsSelector,
  projectRolesWithPendingSubmissionsCountSelector
} from '../../adapters/redux';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const MenuContainer = styled.div`
  width: 20vw;
  background-color: #2b333e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  width: 80vw;
  background-color: #f1f4f9;
  min-height: 100vh;
  padding-bottom: 100px;
`;

function AuthenticatedLayout(props) {
  const { children, session, title } = props;
  // console.log("IndexPage session", session);
  const dispatch = useDispatch();
  const draftProjects = useSelector(draftProjectsSelector);
  const castingProjects = useSelector(castingProjectsSelector);
  const pendingProjects = useSelector(pendingProjectsSelector);
  const openProjects = useSelector(openProjectsSelector);
  const submittedProjects = useSelector(submittedProjectsSelector);
  const projectRolesWithPendingSubmissionsCount =
    useSelector(projectRolesWithPendingSubmissionsCountSelector) || 0;

  useEffect(() => {
    if (session?.error == 'RefreshAccessTokenError') {
      console.log('API error:', session?.error);
      setTimeout(() => {
        // signIn('cognito', { callbackUrl: '/' });
        window.location.href = '/logout';
      }, 2000);
    } else if (session?.error == 'APIAccessTokenError') {
      console.log('API error:', session?.error);
      setTimeout(() => {
        // signIn('cognito', { callbackUrl: '/' });
        window.location.href = '/logout';
      }, 2000);
    }
    dispatch(fetchAllProjectsAction(session?.jwt));
  }, []);

  return (
    <>
      <Head>
        <title>{title || 'Virtual Vox'}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <BodyContainer>
        <MenuContainer>
          <Menu
            session={session}
            draftProjectsCount={draftProjects ? draftProjects.length : 0}
            castingProjectsCount={castingProjects ? castingProjects.length : 0}
            pendingProjectsCount={pendingProjects ? pendingProjects.length : 0}
            openProjectsCount={openProjects ? openProjects.length : 0}
            submittedProjectsCount={submittedProjects ? submittedProjects.length : 0}
            projectRolesWithPendingSubmissionsCount={projectRolesWithPendingSubmissionsCount}
          />
        </MenuContainer>
        <ContentContainer>{React.cloneElement(children, { ...props })}</ContentContainer>
      </BodyContainer>
    </>
  );
}

export default AuthenticatedLayout;
