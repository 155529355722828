import { PropertyListServiceType } from "../../domain/services/property-list-service";
import fetch from "isomorphic-unfetch";
import PropertyList from "../../domain/models/property-list";
import { parseJsonRes } from "../../utils/parse-res";

export class PropertyListService implements PropertyListServiceType {
  async getPropertyLists(): Promise<PropertyList[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/property-lists?_sort=title:ASC`
    );
    const resData = await parseJsonRes(res);
    const propertyLists = resData.map((o) => new PropertyList().fromJson(o));
    return propertyLists;
  }
}
