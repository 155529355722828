class ClientDocument {
  year: number;
  passport?: string[];
  passport_urls?: string[];
  drivers_license?: string[];
  drivers_license_urls?: string[];
  social_security?: string[];
  social_security_urls?: string[];
  vox_authorization?: string[];
  vox_authorization_urls?: string[];
  i9?: string[];
  i9_urls?: string[];
  w4?: string[];
  w4_urls?: string[];
  de4?: string[];
  de4_urls?: string[];
  w9?: string[];
  w9_urls?: string[];
  article_incorporation?: string[];
  article_incorporation_urls?: string[];
  vox_direct_deposit?: string[];
  vox_direct_deposit_urls?: string[];
  year_previous?: number;

  fromJson(data): ClientDocument {
    this.year = data.year;
    if (data.passport) {
      this.passport = data.passport;
    }
    if (data.passport_urls) {
      this.passport_urls = data.passport_urls;
    }
    if (data.drivers_license) {
      this.drivers_license = data.drivers_license;
    }
    if (data.drivers_license_urls) {
      this.drivers_license_urls = data.drivers_license_urls;
    }
    if (data.social_security) {
      this.social_security = data.social_security;
    }
    if (data.social_security_urls) {
      this.social_security_urls = data.social_security_urls;
    }
    if (data.vox_authorization) {
      this.vox_authorization = data.vox_authorization;
    }
    if (data.vox_authorization_urls) {
      this.vox_authorization_urls = data.vox_authorization_urls;
    }
    if (data.i9) {
      this.i9 = data.i9;
    }
    if (data.i9_urls) {
      this.i9_urls = data.i9_urls;
    }
    if (data.w4) {
      this.w4 = data.w4;
    }
    if (data.w4_urls) {
      this.w4_urls = data.w4_urls;
    }
    if (data.de4) {
      this.de4 = data.de4;
    }
    if (data.de4_urls) {
      this.de4_urls = data.de4_urls;
    }
    if (data.w9) {
      this.w9 = data.w9;
    }
    if (data.w9_urls) {
      this.w9_urls = data.w9_urls;
    }
    if (data.article_incorporation) {
      this.article_incorporation = data.article_incorporation;
    }
    if (data.article_incorporation_urls) {
      this.article_incorporation_urls = data.article_incorporation_urls;
    }
    if (data.vox_direct_deposit) {
      this.vox_direct_deposit = data.vox_direct_deposit;
    }
    if (data.vox_direct_deposit_urls) {
      this.vox_direct_deposit_urls = data.vox_direct_deposit_urls;
    }
    if (data.year_previous) {
      this.year_previous = data.year_previous;
    }
    return this;
  }
}

export default ClientDocument;
