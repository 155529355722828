import { ClientServiceType } from "../services/client-service";
import Client from "../models/client";

export class ClientClientBioInteractor {
  jwt: string;
  clientService: ClientServiceType;

  constructor(jwt: string, clientService: ClientServiceType) {
    this.jwt = jwt;
    this.clientService = clientService;
  }

  async getClientForClient(id: number): Promise<Client> {
    return this.clientService.getClientForClient(this.jwt, id);
  }
}
