import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../styles/theme";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { clearSnackbarAction, snackbarSelector } from '../../adapters/redux'
import {
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconContainer = styled.span`
  padding-right: .5rem;
`;
const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
`;

export default function ErrorSnackbar() {
  const dispatch = useDispatch();

  const snackbar = useSelector(snackbarSelector);

  function handleClose() {
    dispatch(clearSnackbarAction());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={snackbar?.errorSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
    >
      <SnackbarContent style={{
        backgroundColor: theme.colors.danger,
      }}
        message={
          <span id="client-snackbar">
            <IconContainer>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </IconContainer>
            {snackbar?.errorSnackbarMessage}
          </span>
        }
        action={
          <IconButton onClick={handleClose}><FontAwesomeIcon icon={faTimesCircle} /></IconButton>
        }
      />
    </Snackbar>
  );
}