import { ClientServiceType } from '../services/client-service';
import Client from '../models/client';

export class ManageClientInteractor {
  jwt: string;
  clientService: ClientServiceType;

  constructor(jwt: string, clientService: ClientServiceType) {
    this.jwt = jwt;
    this.clientService = clientService;
  }

  async getClients(): Promise<Client[]> {
    return this.clientService.getClients(this.jwt);
  }

  async getClient(id: number): Promise<Client> {
    return this.clientService.getClient(this.jwt, id);
  }

  async createClient(data: any): Promise<Client> {
    return this.clientService.createClient(this.jwt, data);
  }

  async updateClient(id: number, data: any): Promise<Client> {
    return this.clientService.updateClient(this.jwt, id, data);
  }

  async deleteClient(id: number): Promise<Client> {
    return this.clientService.deleteClient(this.jwt, id);
  }
}
