import Project from "./project";

class User {
  id: number;
  username: string;
  created_at: Date;
  updated_at: Date;
  projects: Project[];
  email: string;
  name: string;

  fromJson(data): User {
    this.id = data.id;
    this.username = data.username;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.projects) {
      this.projects = data.projects.map((p) => new Project().fromJson(p));
    }
    this.email = data.email;
    this.name = data.name;
    return this;
  }
}

export default User;
