import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { withSoundCloudAudio } from 'react-soundplayer/addons';
import { playerContext } from '../../state/player-context';
import { PlayButton, Timer, Progress, PrevButton, NextButton } from 'react-soundplayer/components';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons';
import { faUndo, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
`;
const ContentContainer = styled.div`
  background-color: #cccccc;
  border-top: 2px solid #bbbbbb;
  box-shadow: 0 -5px 10px rgb(9 30 66 / 25%);
  padding: 0.75rem;
`;
const ContentRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  span {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;
const ClearButton = styled.button`
  background: none;
  border: none;
  outline: none;
`;

function Player(props) {
  // console.log(props);
  const { soundCloudAudio, playing, currentTime } = props;
  const playerState = useContext(playerContext);
  const { currentTrack, resetCurrentTrack } = playerState;
  const isVideo = currentTrack && currentTrack.mime && currentTrack.mime.indexOf('video/') >= 0;

  useEffect(() => {
    if (!isVideo) {
      process.nextTick(() => {
        if (soundCloudAudio && currentTrack && currentTrack.url) {
          soundCloudAudio.play({ streamUrl: currentTrack.url });
          if (currentTrack.startTime) {
            soundCloudAudio.audio.currentTime = currentTrack.startTime;
          }
        }
      });
    }
  }, [currentTrack]);

  // useEffect(() => {
  //   process.nextTick(() => {
  //     if (soundCloudAudio && streamUrl) {
  //       if (playing) {
  //         soundCloudAudio.pause();
  //       } else {
  //         soundCloudAudio.play();
  //       }
  //     }
  //   });
  // }, [playing]);

  useEffect(() => {
    if (currentTrack.endTime) {
      if (currentTime > currentTrack.endTime) {
        resetCurrentTrack();
      }
    }
  }, [currentTime]);

  const onPlayPause = () => {
    if (playing) {
      soundCloudAudio.pause();
    } else {
      soundCloudAudio.play({ streamUrl: currentTrack.url });
    }
  };

  const rewind = () => {
    soundCloudAudio.audio.currentTime = soundCloudAudio.audio.currentTime - 5;
  };

  const handleOnVideoEnded = () => {
    if (props.onStopTrack) {
      props.onStopTrack();
    }
  };

  return (
    <Container>
      <ContentContainer>
        {isVideo && (
          <ContentRow>
            <video
              autoPlay
              controls
              src={currentTrack.url}
              style={{ width: 400 }}
              onEnded={handleOnVideoEnded}>
              Unfortunately your browser does not support the
              <code>video</code> element.
            </video>
            <span>
              <ClearButton onClick={resetCurrentTrack} title="Close">
                <FontAwesomeIcon icon={faTimesCircle} />
              </ClearButton>
            </span>
          </ContentRow>
        )}
        {!isVideo && (
          <ContentRow>
            <ClearButton onClick={onPlayPause}>
              <FontAwesomeIcon icon={playing ? faPauseCircle : faPlayCircle} />
            </ClearButton>
            <ClearButton onClick={rewind}>
              <FontAwesomeIcon icon={faUndo} />
            </ClearButton>
            <Timer {...props} />
            <span>{currentTrack.title}</span>
            <ClearButton onClick={resetCurrentTrack} title="Close">
              <FontAwesomeIcon icon={faTimesCircle} />
            </ClearButton>
          </ContentRow>
        )}
      </ContentContainer>
    </Container>
  );
}

export default withSoundCloudAudio(Player);
