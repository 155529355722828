import { initialState, StateType } from '../state';
import ProjectRole from '../../../domain/models/project-role';

type StateSlice = StateType['projectRole'];

// Constants

const UPDATE_SELECTED_PROJECT_ROLE = 'projectRole/select';
const INCREMENT_INVITATION_COUNT_SELECTED_PROJECT_ROLE =
  'projectRole/select/increment_invitation_count';
const DECREMENT_INVITATION_COUNT_SELECTED_PROJECT_ROLE =
  'projectRole/select/decrement_invitation_count';
const REMOVE_PROJECT_ROLE = 'projectRole/remove';

// Selectors

export const selectedProjectRoleSelector = (state: StateType) =>
  state.projectRole?.selectedId ? state.projectRole.byId[state.projectRole.selectedId] : null;

// Interfaces

export interface UpdateSelectedProjectRoleActionType {
  type: string;
  projectRole: ProjectRole | null;
}

export interface IncrementInvitationCountSelectedProjectRoleActionType {
  type: string;
}

export interface DecrementInvitationCountSelectedProjectRoleActionType {
  type: string;
}

export interface RemoveProjectRoleActionType {
  type: string;
  id: number;
}

// Actions

export const updateSelectedProjectRoleAction = (
  projectRole: ProjectRole
): UpdateSelectedProjectRoleActionType => ({
  type: UPDATE_SELECTED_PROJECT_ROLE,
  projectRole
});

export const incrementInvitationCountSelectedProjectRoleAction =
  (): IncrementInvitationCountSelectedProjectRoleActionType => ({
    type: INCREMENT_INVITATION_COUNT_SELECTED_PROJECT_ROLE
  });

export const decrementInvitationCountSelectedProjectRoleAction =
  (): DecrementInvitationCountSelectedProjectRoleActionType => ({
    type: DECREMENT_INVITATION_COUNT_SELECTED_PROJECT_ROLE
  });

export const removeProjectRoleAction = (id: number): RemoveProjectRoleActionType => ({
  type: REMOVE_PROJECT_ROLE,
  id
});

// Handlers

const updateSelectedHandler = (
  state: StateSlice,
  action: UpdateSelectedProjectRoleActionType
): StateType['projectRole'] => {
  if (action.projectRole) {
    const projectRole = action.projectRole;
    const byId = state?.byId ? { ...state.byId } : {};
    byId[projectRole.id] = projectRole;
    return { ...state, byId, selectedId: projectRole.id };
  }
  return { ...state, selectedId: null };
};

const incrementInvitationCountSelectedHandler = (
  state: StateSlice,
  action: IncrementInvitationCountSelectedProjectRoleActionType
): StateType['projectRole'] => {
  const byId = state?.byId ? { ...state.byId } : {};
  byId[state.selectedId].total_invitations += 1;
  byId[state.selectedId].total_invitations_unsent += 1;
  return { ...state, byId };
};

const decrementInvitationCountSelectedHandler = (
  state: StateSlice,
  action: DecrementInvitationCountSelectedProjectRoleActionType
): StateType['projectRole'] => {
  const byId = state?.byId ? { ...state.byId } : {};
  byId[state.selectedId].total_invitations -= 1;
  byId[state.selectedId].total_invitations_unsent -= 1;
  return { ...state, byId };
};

const removeHandler = (
  state: StateSlice,
  action: RemoveProjectRoleActionType
): StateType['projectRole'] => {
  const id = action.id;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[id]) {
    delete byId[id];
  }
  return { ...state, byId };
};

// Reducer

export const projectRoleReducer = (
  state: StateSlice = initialState.projectRole,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_SELECTED_PROJECT_ROLE:
      return updateSelectedHandler(state, action);
    case INCREMENT_INVITATION_COUNT_SELECTED_PROJECT_ROLE:
      return incrementInvitationCountSelectedHandler(state, action);
    case DECREMENT_INVITATION_COUNT_SELECTED_PROJECT_ROLE:
      return decrementInvitationCountSelectedHandler(state, action);
    case REMOVE_PROJECT_ROLE:
      return removeHandler(state, action);
    default:
      return state;
  }
};
