import Invitation from './invitation';
import Media from './media';
import Project from './project';
import Submission from './submission';

class ProjectRole {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  project: Project;
  subtitle: string;
  description: string;
  script: string;
  total_submissions: number;
  total_submissions_approved: number;
  total_submissions_pending: number;
  attachments: Media[];
  submissions: Submission[];
  total_invitations: number;
  total_invitations_unsent: number;
  author: number;
  invitations: Invitation[];

  fromJson(data): ProjectRole {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.project && !Number.isInteger(data.project)) {
      this.project = new Project().fromJson(data.project);
    }
    this.subtitle = data.subtitle;
    this.description = data.description;
    this.script = data.script;
    this.total_submissions = data.total_submissions;
    this.total_submissions_approved = data.total_submissions_approved;
    this.total_submissions_pending = data.total_submissions_pending;
    if (data.attachments) {
      this.attachments = data.attachments.map((a) => new Media().fromJson(a));
    }
    if (data.submissions) {
      this.submissions = data.submissions.map((a) => new Submission().fromJson(a));
    }
    this.total_invitations = data.total_invitations;
    this.total_invitations_unsent = data.total_invitations_unsent;
    this.author = data.author;
    if (data.invitations) {
      this.invitations = data.invitations.map((a) => new Invitation().fromJson(a));
    }
    return this;
  }
}

export default ProjectRole;
