import { logError } from '../../../utils/log';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  updateSelectedProjectRoleAction,
  removeProjectRoleAction
} from '../actions/project-role-action';
import { updateSelectedInvitationsAction } from '../actions/invitation-action';
import { updateSelectedSubmissionsAction } from '../actions/submission-action';
import { ProjectRoleService } from '../../../data/services/data-project-role-service';
import { InvitationService } from '../../../data/services/data-invitation-service';
import { SubmissionService } from '../../../data/services/data-submission-service';
import { ManageProjectRoleInteractor } from '../../../domain/usecases/manage-project-role-interactor';
import {
  SelectProjectRoleInteractor,
  SelectedProjectRoleType
} from '../../../domain/usecases/select-project-role-interactor';

// Constants

export const SELECT_PROJECT_ROLE = 'projectRole/saga/select';
export const SAVE_SELECTED_PROJECT_ROLE = 'projectRole/saga/selected/save';
export const NEW_PROJECT_ROLE = 'projectRole/saga/new';
export const DELETE_PROJECT_ROLE = 'projectRole/saga/delete';

// Interfaces

interface SelectProjectRoleActionType {
  type: string;
  jwt: string;
  id: number;
}

interface SaveProjectRoleForSelectedActionType {
  type: string;
  jwt: string;
  id: number;
  data: any;
  completed: () => void;
}

interface NewProjectRoleActionType {
  type: string;
  jwt: string;
  data: any;
  completed: (id: number) => void;
}

interface DeleteProjectRoleActionType {
  type: string;
  jwt: string;
  id: number;
  completed: () => void;
}

// Actions

export const selectProjectRoleAction = (jwt: string, id: number): SelectProjectRoleActionType => ({
  type: SELECT_PROJECT_ROLE,
  jwt,
  id
});

export const saveProjectRoleForSelectedAction = (
  jwt: string,
  id: number,
  data: any,
  completed?: () => void
): SaveProjectRoleForSelectedActionType => ({
  type: SAVE_SELECTED_PROJECT_ROLE,
  jwt,
  id,
  data,
  completed
});

export const newProjectRoleAction = (
  jwt: string,
  data: any,
  completed?: (id: number) => void
): NewProjectRoleActionType => ({
  type: NEW_PROJECT_ROLE,
  jwt,
  data,
  completed
});

export const deleteProjectRoleAction = (
  jwt: string,
  id: number,
  completed?: () => void
): DeleteProjectRoleActionType => ({
  type: DELETE_PROJECT_ROLE,
  jwt,
  id,
  completed
});

// Sagas

function* selectProjectRoleSaga(action: SelectProjectRoleActionType) {
  const { id } = action;
  try {
    const projectRoleService = new ProjectRoleService();
    const invitationService = new InvitationService();
    const submissionService = new SubmissionService();
    const interactor = new SelectProjectRoleInteractor(
      action.jwt,
      projectRoleService,
      invitationService,
      submissionService
    );

    yield put(updateSelectedProjectRoleAction(null));
    yield put(updateSelectedSubmissionsAction(null));
    yield put(updateSelectedInvitationsAction(null));

    const selected: SelectedProjectRoleType = yield interactor.getSelected(id);
    yield put(updateSelectedProjectRoleAction(selected.projectRole));
    yield put(updateSelectedSubmissionsAction(selected.submissions));
    yield put(updateSelectedInvitationsAction(selected.invitations));
  } catch (err) {
    yield put(logError(err, 'Project Roles'));
  }
}

function* saveProjectRoleForSelectedSaga(action: SaveProjectRoleForSelectedActionType) {
  const { id, data, completed } = action;
  try {
    const service = new ProjectRoleService();
    const interactor = new ManageProjectRoleInteractor(action.jwt, service);

    const projectRole = yield interactor.updateProjectRole(id, data);
    yield put(updateSelectedProjectRoleAction(projectRole));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Project Roles'));
  }
}

function* newProjectRoleSaga(action: NewProjectRoleActionType) {
  const { data, completed } = action;
  try {
    const service = new ProjectRoleService();
    const interactor = new ManageProjectRoleInteractor(action.jwt, service);

    const projectRole = yield interactor.createProjectRole(data);
    // yield put(updateSelectedProjectRoleAction(projectRole));
    if (completed) {
      completed(projectRole.id);
    }
  } catch (err) {
    yield put(logError(err, 'Project Roles'));
  }
}

function* deleteProjectRoleSaga(action: DeleteProjectRoleActionType) {
  const { id, completed } = action;
  try {
    const service = new ProjectRoleService();
    const interactor = new ManageProjectRoleInteractor(action.jwt, service);

    yield interactor.deleteProjectRole(id);
    yield put(removeProjectRoleAction(id));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Project Roles'));
  }
}

// Combined Sagas

export default [
  takeLatest(SELECT_PROJECT_ROLE, selectProjectRoleSaga),
  takeEvery(SAVE_SELECTED_PROJECT_ROLE, saveProjectRoleForSelectedSaga),
  takeEvery(NEW_PROJECT_ROLE, newProjectRoleSaga),
  takeEvery(DELETE_PROJECT_ROLE, deleteProjectRoleSaga)
];
