import { BuyerServiceType } from "../services/buyer-service";
import Buyer from "../models/buyer";

export class ManageBuyerInteractor {
  jwt: string;
  buyerService: BuyerServiceType;

  constructor(jwt: string, buyerService: BuyerServiceType) {
    this.jwt = jwt;
    this.buyerService = buyerService;
  }

  async getBuyers(): Promise<Buyer[]> {
    return this.buyerService.getBuyers(this.jwt);
  }

  async getBuyer(id: number): Promise<Buyer> {
    return this.buyerService.getBuyer(this.jwt, id);
  }

  async createBuyer(data: any): Promise<Buyer> {
    return this.buyerService.createBuyer(this.jwt, data);
  }

  async updateBuyer(id: number, data: any): Promise<Buyer> {
    return this.buyerService.updateBuyer(this.jwt, id, data);
  }
}
