import Project from "./project";

class Buyer {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  projects: Project[];
  submission_email: string;
  media_file_format: string;
  author: number;
  submission_email_cc: string;

  fromJson(data): Buyer {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.projects) {
      this.projects = data.projects.map((a) => new Project().fromJson(a));
    }
    this.submission_email = data.submission_email;
    this.media_file_format = data.media_file_format;
    this.author = data.author;
    this.submission_email_cc = data.submission_email_cc;
    return this;
  }
}

export default Buyer;
