import Property from './property';

class PropertyList {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  properties: Property[];

  fromJson(data): PropertyList {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.properties) {
      this.properties = data.properties.map((p) => new Property().fromJson(p));
    }
    return this;
  }
}

export default PropertyList;

export type FilterType = {
  [key: string]: [
    {
      label: string;
      value: number;
      priority: number | null;
    }
  ];
};
