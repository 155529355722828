import { initialState, StateType } from "../state";
import PropertyList from "../../../domain/models/property-list";

type StateSlice = StateType["propertyList"];

// Constants

const UPDATE_PROPERTY_LISTS = "propertyList/all/update";

// Selectors

export const propertyListsSelector = (state: StateType) =>
  state.propertyList?.propertyLists;

// Interfaces

export interface UpdatePropertyListsActionType {
  type: string;
  propertyLists: PropertyList[] | null;
}

// Actions

export const updatePropertyListsAction = (
  propertyLists: PropertyList[]
): UpdatePropertyListsActionType => ({
  type: UPDATE_PROPERTY_LISTS,
  propertyLists,
});

// Handlers

const updateHandler = (
  state: StateSlice,
  action: UpdatePropertyListsActionType
): StateType["propertyList"] => {
  return { ...state, propertyLists: action.propertyLists };
};

// Reducer

export const propertyListReducer = (
  state: StateSlice = initialState.propertyList,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_PROPERTY_LISTS:
      return updateHandler(state, action);
    default:
      return state;
  }
};
