import { InvitationServiceType } from '../services/invitation-service';
import Invitation from '../models/invitation';

export class ManageInvitationInteractor {
  jwt: string;
  invitationService: InvitationServiceType;

  constructor(jwt: string, invitationService: InvitationServiceType) {
    this.jwt = jwt;
    this.invitationService = invitationService;
  }

  async createInvitation(data: any): Promise<Invitation> {
    return this.invitationService.createInvitation(this.jwt, data);
  }

  async deleteInvitation(invitationId: number): Promise<Invitation> {
    return this.invitationService.deleteInvitation(this.jwt, invitationId);
  }

  async updateInvitationIsOverscale(
    invitationId: number,
    isOverscale: boolean
  ): Promise<Invitation> {
    return this.invitationService.updateInvitationIsOverscale(this.jwt, invitationId, isOverscale);
  }

  async updateInvitationIsRequested(
    invitationId: number,
    isRequested: boolean
  ): Promise<Invitation> {
    return this.invitationService.updateInvitationIsRequested(this.jwt, invitationId, isRequested);
  }
}
