import { logError } from '../../../utils/log';
import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  addSelectedSubmissionAction,
  updateSelectedSubmissionAction,
  removeSelectedSubmissionAction,
  updateEditSubmissionAction,
  editSubmissionSelector
} from '../actions/submission-action';
import { SubmissionService } from '../../../data/services/data-submission-service';
import { ManageSubmissionInteractor } from '../../../domain/usecases/manage-submission-interactor';
import { StateType } from '../state';

// Constants

export const NEW_SELECTED_SUBMISSION = 'submission/saga/selected/new';
export const SAVE_SELECTED_SUBMISSION = 'submission/saga/selected/save';
export const DELETE_SELECTED_SUBMISSION = 'submission/saga/selected/delete';
export const TOGGLE_STATUS_SELECTED_SUBMISSION = 'submission/saga/selected/toggleStatus';
export const DUPLICATE_SELECTED_SUBMISSION = 'submission/saga/selected/duplicate';
export const SAVE_EDIT_SUBMISSION = 'submission/saga/edit/save';
export const TRIM_EDIT_SUBMISSION = 'submission/saga/edit/trim';
export const NEW_MEDIA_EDIT_SUBMISSION = 'submission/saga/edit/newMedia';

// Interfaces

interface NewSubmissionForSelectedActionType {
  type: string;
  jwt: string;
  data: any;
}

interface SaveSubmissionForSelectedActionType {
  type: string;
  jwt: string;
  id: number;
  data: any;
}

interface DeleteSubmissionForSelectedActionType {
  type: string;
  jwt: string;
  id: number;
}

interface ToggleStatusSubmissionForSelectedActionType {
  type: string;
  jwt: string;
  id: number;
  status: string;
  completed?: () => void;
}

interface DuplicateSubmissionForSelectedActionType {
  type: string;
  jwt: string;
  id: number;
}

interface SaveEditSubmissionActionType {
  type: string;
  jwt: string;
  id: number;
  data: any;
}

interface TrimEditSubmissionActionType {
  type: string;
  jwt: string;
  startTime: number;
  endTime: number;
}

interface NewMediaEditSubmissionActionType {
  type: string;
  jwt: string;
  file: File;
}

// Actions

export const newSubmissionForSelectedAction = (
  jwt: string,
  data: any
): NewSubmissionForSelectedActionType => ({
  type: NEW_SELECTED_SUBMISSION,
  jwt,
  data
});

export const saveSubmissionForSelectedAction = (
  jwt: string,
  id: number,
  data: any
): SaveSubmissionForSelectedActionType => ({
  type: SAVE_SELECTED_SUBMISSION,
  jwt,
  id,
  data
});

export const deleteSubmissionForSelectedAction = (
  jwt: string,
  id: number
): DeleteSubmissionForSelectedActionType => ({
  type: DELETE_SELECTED_SUBMISSION,
  jwt,
  id
});

export const toggleStatusSubmissionForSelectedAction = (
  jwt: string,
  id: number,
  status: string,
  completed?: () => void
): ToggleStatusSubmissionForSelectedActionType => ({
  type: TOGGLE_STATUS_SELECTED_SUBMISSION,
  jwt,
  id,
  status,
  completed
});

export const duplicateSubmissionForSelectedAction = (
  jwt: string,
  id: number
): DuplicateSubmissionForSelectedActionType => ({
  type: DUPLICATE_SELECTED_SUBMISSION,
  jwt,
  id
});

export const saveEditSubmissionAction = (
  jwt: string,
  id: number,
  data: any
): SaveEditSubmissionActionType => ({
  type: SAVE_EDIT_SUBMISSION,
  jwt,
  id,
  data
});

export const trimEditSubmissionAction = (
  jwt: string,
  startTime: number,
  endTime: number
): TrimEditSubmissionActionType => ({
  type: TRIM_EDIT_SUBMISSION,
  jwt,
  startTime,
  endTime
});

export const newMediaEditSubmissionAction = (
  jwt: string,
  file: File
): NewMediaEditSubmissionActionType => ({
  type: NEW_MEDIA_EDIT_SUBMISSION,
  jwt,
  file
});

// Sagas

function* newSubmissionForSelectedSaga(action: NewSubmissionForSelectedActionType) {
  const { data } = action;
  try {
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    const submission = yield interactor.createSubmission(data);
    yield put(addSelectedSubmissionAction(submission));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* saveSubmissionForSelectedSaga(action: SaveSubmissionForSelectedActionType) {
  const { id, data } = action;
  try {
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    const submission = yield interactor.updateSubmission(id, data);
    yield put(updateSelectedSubmissionAction(submission));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* deleteSubmissionForSelectedSaga(action: DeleteSubmissionForSelectedActionType) {
  const { id } = action;
  try {
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    yield interactor.deleteSubmission(id);
    yield put(removeSelectedSubmissionAction(id));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* toggleStatusSubmissionForSelectedSaga(
  action: ToggleStatusSubmissionForSelectedActionType
) {
  const { id, status, completed } = action;
  try {
    // update state before saving to API for faster UI
    const stateSubmission = yield select((state: StateType) => state.submission.byId[id]);
    stateSubmission.status = status;
    yield put(updateSelectedSubmissionAction(stateSubmission));

    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    yield interactor.updateSubmission(id, { status });
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* duplicateSubmissionForSelectedSaga(action: DuplicateSubmissionForSelectedActionType) {
  const { id } = action;
  try {
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    const submission = yield interactor.duplicateSubmission(id);
    yield put(addSelectedSubmissionAction(submission));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* saveEditSubmissionSaga(action: SaveEditSubmissionActionType) {
  const { id, data } = action;
  try {
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    const submission = yield interactor.updateSubmission(id, data);
    yield put(updateEditSubmissionAction(null));
    yield put(updateSelectedSubmissionAction(submission));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* trimEditSubmissionSaga(action: TrimEditSubmissionActionType) {
  const { startTime, endTime } = action;
  try {
    const editSubmission = yield select(editSubmissionSelector);
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    const submission = yield interactor.trimSubmission(editSubmission.id, startTime, endTime);
    yield put(updateEditSubmissionAction(null));
    yield put(updateSelectedSubmissionAction(submission));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

function* newMediaEditSubmissionSaga(action: NewMediaEditSubmissionActionType) {
  const { file } = action;
  try {
    const editSubmission = yield select(editSubmissionSelector);
    const service = new SubmissionService();
    const interactor = new ManageSubmissionInteractor(action.jwt, service);

    const submission = yield interactor.updateSubmission(editSubmission.id, {
      new_media: file
    });
    yield put(updateEditSubmissionAction(null));
    yield put(updateSelectedSubmissionAction(submission));
  } catch (err) {
    yield put(logError(err, 'Submissions'));
  }
}

// Combined Sagas

export default [
  takeEvery(NEW_SELECTED_SUBMISSION, newSubmissionForSelectedSaga),
  takeEvery(SAVE_SELECTED_SUBMISSION, saveSubmissionForSelectedSaga),
  takeEvery(DELETE_SELECTED_SUBMISSION, deleteSubmissionForSelectedSaga),
  takeEvery(TOGGLE_STATUS_SELECTED_SUBMISSION, toggleStatusSubmissionForSelectedSaga),
  takeEvery(DUPLICATE_SELECTED_SUBMISSION, duplicateSubmissionForSelectedSaga),
  takeEvery(SAVE_EDIT_SUBMISSION, saveEditSubmissionSaga),
  takeEvery(TRIM_EDIT_SUBMISSION, trimEditSubmissionSaga),
  takeEvery(NEW_MEDIA_EDIT_SUBMISSION, newMediaEditSubmissionSaga)
];
