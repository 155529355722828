import { initialState, StateType } from '../state';
import Client from '../../../domain/models/client';

type StateSlice = StateType['client'];

// Constants

const UPDATE_CLIENTS = 'client/all/update';
const ADD_CLIENT = 'client/add';
const UPDATE_CLIENT = 'client/update';
const REMOVE_CLIENT = 'client/remove';
const UPDATE_SELECTED_CLIENT = 'client/select';

// Selectors

export const selectedClientSelector = (state: StateType) =>
  state.client?.selectedId ? state.client.byId[state.client.selectedId] : null;

export const clientsSelector = (state: StateType) =>
  state.client?.allIds?.map((id) => state.client.byId[id]);

// Interfaces

export interface UpdateClientsActionType {
  type: string;
  clients: Client[] | null;
}

export interface AddClientActionType {
  type: string;
  client: Client | null;
}

export interface UpdateClientActionType {
  type: string;
  client: Client | null;
}

export interface RemoveClientActionType {
  type: string;
  client: Client | null;
}

export interface UpdateSelectedClientActionType {
  type: string;
  client: Client | null;
}

// Actions

export const updateClientsAction = (clients: Client[] | null): UpdateClientsActionType => ({
  type: UPDATE_CLIENTS,
  clients
});

export const addClientAction = (client: Client): UpdateClientActionType => ({
  type: UPDATE_CLIENT,
  client
});

export const updateClientAction = (client: Client): UpdateClientActionType => ({
  type: UPDATE_CLIENT,
  client
});

export const removeClientAction = (client: Client): RemoveClientActionType => ({
  type: REMOVE_CLIENT,
  client
});

export const updateSelectedClientAction = (client: Client): UpdateSelectedClientActionType => ({
  type: UPDATE_SELECTED_CLIENT,
  client
});

// Handlers

const updateAllHandler = (
  state: StateSlice,
  action: UpdateClientsActionType
): StateType['client'] => {
  const clients = action.clients;
  if (clients) {
    const byId = state?.byId ? { ...state.byId } : {};
    const allIds = clients.map((o) => o.id);
    clients.forEach((client) => {
      byId[client.id] = client;
    });
    return { ...state, byId, allIds };
  }
  return { ...state, allIds: null };
};

const addHandler = (state: StateSlice, action: AddClientActionType): StateType['client'] => {
  const client = action.client;
  const byId = state?.byId ? { ...state.byId } : {};
  const allIds = state?.allIds ? [...state.allIds] : [];
  allIds.unshift(client.id);
  byId[client.id] = client;
  return { ...state, byId, allIds };
};

const updateHandler = (state: StateSlice, action: UpdateClientActionType): StateType['client'] => {
  const client = action.client;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[client.id]) {
    byId[client.id] = client;
  }
  return { ...state, byId };
};

const removeHandler = (state: StateSlice, action: RemoveClientActionType): StateType['client'] => {
  const client = action.client;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[client.id]) {
    delete byId[client.id];
  }
  return { ...state, byId };
};

const updateSelectedHandler = (
  state: StateSlice,
  action: UpdateSelectedClientActionType
): StateType['client'] => {
  if (action.client) {
    const client = action.client;
    const byId = state?.byId ? { ...state.byId } : {};
    byId[client.id] = client;
    return { ...state, byId, selectedId: client.id };
  }
  return { ...state, selectedId: null };
};

// Reducer

export const clientReducer = (state: StateSlice = initialState.client, action: any): StateSlice => {
  switch (action.type) {
    case UPDATE_CLIENTS:
      return updateAllHandler(state, action);
    case ADD_CLIENT:
      return addHandler(state, action);
    case UPDATE_CLIENT:
      return updateHandler(state, action);
    case REMOVE_CLIENT:
      return removeHandler(state, action);
    case UPDATE_SELECTED_CLIENT:
      return updateSelectedHandler(state, action);
    default:
      return state;
  }
};
