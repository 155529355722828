import Client from "./client";
import Media from "./media";
import ProjectRole from "./project-role";

class Submission {
  id: number;
  status: string;
  project_role: ProjectRole;
  created_at: Date;
  updated_at: Date;
  client: Client;
  media: Media;
  duration: number;
  media_original: Media;
  nda: Media;

  fromJson(data): Submission {
    this.id = data.id;
    this.status = data.status;
    if (data.project_role && !Number.isInteger(data.project_role)) {
      this.project_role = new ProjectRole().fromJson(data.project_role);
    }
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.client && !Number.isInteger(data.client)) {
      this.client = new Client().fromJson(data.client);
    }
    if (data.media && !Number.isInteger(data.media)) {
      this.media = new Media().fromJson(data.media);
    }
    this.duration = data.duration;
    if (data.media_original && !Number.isInteger(data.media_original)) {
      this.media_original = new Media().fromJson(data.media_original);
    }
    if (data.nda && !Number.isInteger(data.nda)) {
      this.nda = new Media().fromJson(data.nda);
    }
    return this;
  }
}

export default Submission;
