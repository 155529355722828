import { logError } from '../../../utils/log';
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
  updateBuyersAction,
  addBuyerAction,
  updateBuyerAction,
  updateSelectedBuyerAction
} from '../actions/buyer-action';
import { BuyerService } from '../../../data/services/data-buyer-service';
import { ManageBuyerInteractor } from '../../../domain/usecases/manage-buyer-interactor';

// Constants

export const FETCH_ALL_BUYERS = 'buyer/saga/fetchAll';
export const SELECT_BUYER = 'buyer/saga/select';
export const NEW_BUYER = 'buyer/saga/new';
export const SAVE_BUYER = 'buyer/saga/save';

// Interfaces

interface FetchAllBuyersActionType {
  type: string;
  jwt: string;
}

interface SelectBuyerActionType {
  type: string;
  jwt: string;
  id: number;
}

interface NewBuyerActionType {
  type: string;
  jwt: string;
  data: any;
  completed: (id: number) => void;
}

interface SaveBuyerActionType {
  type: string;
  jwt: string;
  id: number;
  data: any;
  completed: () => void;
}

// Actions

export const fetchAllBuyersAction = (jwt: string): FetchAllBuyersActionType => ({
  type: FETCH_ALL_BUYERS,
  jwt
});

export const selectBuyerAction = (jwt: string, id: number): SelectBuyerActionType => ({
  type: SELECT_BUYER,
  jwt,
  id
});

export const newBuyerAction = (
  jwt: string,
  data: any,
  completed?: (id: number) => void
): NewBuyerActionType => ({
  type: NEW_BUYER,
  jwt,
  data,
  completed
});

export const saveBuyerAction = (
  jwt: string,
  id: number,
  data: any,
  completed?: () => void
): SaveBuyerActionType => ({
  type: SAVE_BUYER,
  jwt,
  id,
  data,
  completed
});

// Sagas

function* fetchAllBuyersSaga(action: FetchAllBuyersActionType) {
  try {
    const service = new BuyerService();
    const interactor = new ManageBuyerInteractor(action.jwt, service);

    const buyers = yield interactor.getBuyers();
    yield put(updateBuyersAction(buyers));
  } catch (err) {
    yield put(logError(err, 'Buyers'));
  }
}

function* selectBuyerSaga(action: SelectBuyerActionType) {
  const { id } = action;
  try {
    const service = new BuyerService();
    const interactor = new ManageBuyerInteractor(action.jwt, service);

    yield put(updateSelectedBuyerAction(null));

    const buyer = yield interactor.getBuyer(id);
    yield put(updateSelectedBuyerAction(buyer));
  } catch (err) {
    yield put(logError(err, 'Buyers'));
  }
}

function* newBuyerSaga(action: NewBuyerActionType) {
  const { data, completed } = action;
  try {
    const service = new BuyerService();
    const interactor = new ManageBuyerInteractor(action.jwt, service);

    const buyer = yield interactor.createBuyer(data);
    yield put(addBuyerAction(buyer));
    if (completed) {
      completed(buyer.id);
    }
  } catch (err) {
    yield put(logError(err, 'Buyers'));
  }
}

function* saveBuyerSaga(action: SaveBuyerActionType) {
  const { id, data, completed } = action;
  try {
    const service = new BuyerService();
    const interactor = new ManageBuyerInteractor(action.jwt, service);

    const buyer = yield interactor.updateBuyer(id, data);
    yield put(updateBuyerAction(buyer));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Buyers'));
  }
}

// Combined Sagas

export default [
  takeLatest(FETCH_ALL_BUYERS, fetchAllBuyersSaga),
  takeLatest(SELECT_BUYER, selectBuyerSaga),
  takeEvery(NEW_BUYER, newBuyerSaga),
  takeEvery(SAVE_BUYER, saveBuyerSaga)
];
