import { InvitationServiceType } from '../../domain/services/invitation-service';
import fetch from 'isomorphic-unfetch';
import Invitation from '../../domain/models/invitation';
import { parseJsonRes } from '../../utils/parse-res';

export class InvitationService implements InvitationServiceType {
  async getInvitations(jwt: string, projectRoleId: number): Promise<Invitation[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/invitations?project_role=${projectRoleId}&_sort=created_at:DESC&_limit=2000`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const invitations = resData.map((o) => new Invitation().fromJson(o));
    return invitations;
  }

  async createInvitation(jwt: string, data: any): Promise<Invitation> {
    console.log('createInvitation', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Invitation().fromJson(resData);
  }

  async updateInvitation(jwt: string, invitationId: number, data: any): Promise<Invitation> {
    console.log('updateInvitation', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/${invitationId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Invitation().fromJson(resData);
  }

  async deleteInvitation(jwt: string, invitationId: number): Promise<Invitation> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/${invitationId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    return new Invitation().fromJson(resData);
  }

  async sendTest(
    jwt: string,
    projectRoleId: number,
    subject: string,
    title: string,
    body: string,
    email: string
  ): Promise<void> {
    const data = { projectRoleId, subject, title, body, email };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/sendtest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    }
    throw new Error('Send email failed.');
  }

  async send(
    jwt: string,
    projectRoleId: number,
    subject: string,
    title: string,
    body: string
  ): Promise<void> {
    const data = { projectRoleId, subject, title, body };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    }
    throw new Error('Send email failed.');
  }

  async getInvitationByUnique(
    unique: string | number
  ): Promise<{ invitation: Invitation; valid: boolean }> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/client/${unique}`);
    const resData = await parseJsonRes(res);
    return { invitation: new Invitation().fromJson(resData.invitation), valid: resData.valid };
  }

  async read(invitationId: number): Promise<void> {
    console.log('data-invitation-service read', invitationId);
    // const res =
    await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/${invitationId}/read`, {
      method: 'PUT'
    });
    // const resData = await parseJsonRes(res);
    // return new Invitation().fromJson(resData);
  }

  async passed(invitationId: number): Promise<void> {
    console.log('data-invitation-service passed', invitationId);
    // const res =
    await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/${invitationId}/passed`, {
      method: 'PUT'
    });
    // const resData = await parseJsonRes(res);
    // return new Invitation().fromJson(resData);
  }

  async updateInvitationIsOverscale(
    jwt: string,
    invitationId: number,
    isOverscale: boolean
  ): Promise<Invitation> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/${invitationId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ is_overscale: isOverscale })
    });
    const resData = await parseJsonRes(res);
    return new Invitation().fromJson(resData);
  }

  async updateInvitationIsRequested(
    jwt: string,
    invitationId: number,
    isRequested: boolean
  ): Promise<Invitation> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/invitations/${invitationId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ is_requested: isRequested })
    });
    const resData = await parseJsonRes(res);
    return new Invitation().fromJson(resData);
  }
}
