import { ProjectServiceType } from '../services/project-service';

export class SendProjectInteractor {
  jwt: string;
  projectService: ProjectServiceType;

  constructor(jwt: string, projectService: ProjectServiceType) {
    this.jwt = jwt;
    this.projectService = projectService;
  }

  async sendManual(
    projectId: number,
    subject: string,
    title: string,
    body: string,
    email: string,
    message: string
  ): Promise<void> {
    return this.projectService.sendManual(
      this.jwt,
      projectId,
      subject,
      title,
      body,
      email,
      message
    );
  }

  async send(
    projectId: number,
    subject: string,
    title: string,
    body: string,
    message: string
  ): Promise<void> {
    return this.projectService.send(this.jwt, projectId, subject, title, body, message);
  }

  async notifyRequestCasting(projectId: number): Promise<void> {
    return this.projectService.notifyRequestCasting(this.jwt, projectId);
  }

  async notifyRequestApproval(projectId: number): Promise<void> {
    return this.projectService.notifyRequestApproval(this.jwt, projectId);
  }

  async notifyApproved(projectId: number): Promise<void> {
    return this.projectService.notifyApproved(this.jwt, projectId);
  }
}
