import AuthenticatedLayout from "./authenticated";
import ClientLayout from "./client";
import AnonymousLayout from "./anonymous";

const layouts = {
  authenticated: AuthenticatedLayout,
  client: ClientLayout,
};

const LayoutWrapper = (props) => {
  // to get the text value of the assigned layout of each component
  let Layout = layouts[props.children.type.layout];
  if (Layout == null) {
    Layout = layouts[props.layout];
  }
  // if we have a registered layout render children with said layout
  if (Layout != null) {
    return <Layout {...props}>{props.children}</Layout>;
  }
  // if not render children with fragment
  return <AnonymousLayout {...props}>{props.children}</AnonymousLayout>;
};

export default LayoutWrapper;
