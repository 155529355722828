import { logError } from '../../../utils/log';
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
  updateProjectsAction,
  addProjectAction,
  updateProjectAction,
  updateSelectedProjectAction,
  selectedProjectSelector,
  removeProjectAction
} from '../actions/project-action';
import { ProjectService } from '../../../data/services/data-project-service';
import { ManageProjectInteractor } from '../../../domain/usecases/manage-project-interactor';
import { BuyerService } from '../../../data/services/data-buyer-service';
import { ManageBuyerInteractor } from '../../../domain/usecases/manage-buyer-interactor';
import { SendProjectInteractor } from '../../../domain/usecases/send-project-interactor';

// Constants

export const FETCH_ALL_PROJECTS = 'project/saga/fetchAll';
export const SELECT_PROJECT = 'project/saga/select';
export const SAVE_PROJECT_STATUS = 'project/saga/saveStatus';
export const SAVE_PROJECT_REQUEST_CASTING = 'project/saga/saveRequestCasting';
export const SAVE_PROJECT_REQUEST_APPROVAL = 'project/saga/saveRequestApproval';
export const SAVE_PROJECT_APPROVED = 'project/saga/saveRequestApproved';
export const NEW_PROJECT = 'project/saga/new';
export const SAVE_PROJECT = 'project/saga/save';
export const DELETE_PROJECT = 'project/saga/delete';

// Interfaces

interface FetchAllProjectsActionType {
  type: string;
  jwt: string;
}

interface SelectProjectActionType {
  type: string;
  jwt: string;
  id: number;
}

interface SaveProjectStatusActionType {
  type: string;
  jwt: string;
  id: number;
  status: string;
}

interface SaveProjectRequestCastingActionType {
  type: string;
  jwt: string;
  id: number;
}

interface SaveProjectRequestApprovalActionType {
  type: string;
  jwt: string;
  id: number;
}

interface SaveProjectApprovedActionType {
  type: string;
  jwt: string;
  id: number;
}

interface NewProjectActionType {
  type: string;
  jwt: string;
  data: any;
  completed: (id: number) => void;
}

interface SaveProjectActionType {
  type: string;
  jwt: string;
  id: number;
  data: any;
  completed: () => void;
}

interface DeleteProjectActionType {
  type: string;
  jwt: string;
  id: number;
  completed: () => void;
}

// Actions

export const fetchAllProjectsAction = (jwt: string): FetchAllProjectsActionType => ({
  type: FETCH_ALL_PROJECTS,
  jwt
});

export const selectProjectAction = (jwt: string, id: number): SelectProjectActionType => ({
  type: SELECT_PROJECT,
  jwt,
  id
});

export const saveProjectStatusAction = (
  jwt: string,
  id: number,
  status: string
): SaveProjectStatusActionType => ({
  type: SAVE_PROJECT_STATUS,
  jwt,
  id,
  status
});

export const saveProjectRequestCastingAction = (
  jwt: string,
  id: number
): SaveProjectRequestCastingActionType => ({
  type: SAVE_PROJECT_REQUEST_CASTING,
  jwt,
  id
});

export const saveProjectRequestApprovalAction = (
  jwt: string,
  id: number
): SaveProjectRequestApprovalActionType => ({
  type: SAVE_PROJECT_REQUEST_APPROVAL,
  jwt,
  id
});

export const saveProjectApprovedAction = (
  jwt: string,
  id: number
): SaveProjectApprovedActionType => ({
  type: SAVE_PROJECT_APPROVED,
  jwt,
  id
});

export const newProjectAction = (
  jwt: string,
  data: any,
  completed?: (id: number) => void
): NewProjectActionType => ({
  type: NEW_PROJECT,
  jwt,
  data,
  completed
});

export const saveProjectAction = (
  jwt: string,
  id: number,
  data: any,
  completed?: () => void
): SaveProjectActionType => ({
  type: SAVE_PROJECT,
  jwt,
  id,
  data,
  completed
});

export const deleteProjectAction = (
  jwt: string,
  id: number,
  completed?: () => void
): DeleteProjectActionType => ({
  type: DELETE_PROJECT,
  jwt,
  id,
  completed
});

// Sagas

function* fetchAllProjectsSaga(action: FetchAllProjectsActionType) {
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);

    const projects = yield interactor.getProjects();
    yield put(updateProjectsAction(projects));
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* selectProjectSaga(action: SelectProjectActionType) {
  const { id } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);

    yield put(updateSelectedProjectAction(null));

    const project = yield interactor.getProject(id);
    yield put(updateSelectedProjectAction(project));
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* saveProjectStatusSaga(action: SaveProjectStatusActionType) {
  const { id, status } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);

    const project = yield interactor.updateProjectStatus(id, status);
    yield put(updateProjectAction(project));
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* saveProjectRequestCastingSaga(action: SaveProjectRequestCastingActionType) {
  const { id } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);

    const project = yield interactor.updateProjectStatus(id, 'casting');
    yield put(updateProjectAction(project));

    const sendProjectInteractor = new SendProjectInteractor(action.jwt, service);
    yield sendProjectInteractor.notifyRequestCasting(project.id);
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* saveProjectRequestApprovalSaga(action: SaveProjectRequestApprovalActionType) {
  const { id } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);

    const project = yield interactor.updateProjectStatus(id, 'pending');
    yield put(updateProjectAction(project));

    const sendProjectInteractor = new SendProjectInteractor(action.jwt, service);
    yield sendProjectInteractor.notifyRequestApproval(project.id);
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* saveProjectApprovedSaga(action: SaveProjectApprovedActionType) {
  const { id } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);

    const project = yield interactor.updateProjectStatus(id, 'open');
    yield put(updateProjectAction(project));

    const sendProjectInteractor = new SendProjectInteractor(action.jwt, service);
    yield sendProjectInteractor.notifyApproved(project.id);
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* newProjectSaga(action: NewProjectActionType) {
  const { data, completed } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);
    const buyerService = new BuyerService();
    const buyerInteractor = new ManageBuyerInteractor(action.jwt, buyerService);
    if (data.new_buyer_title && data.new_buyer_title) {
      const buyer = yield buyerInteractor.createBuyer({
        title: data.new_buyer_title,
        submission_email: data.new_buyer_submission_email
      });
      data.buyer = buyer.id;
    }
    const project = yield interactor.createProject(data);
    yield put(addProjectAction(project));
    if (completed) {
      completed(project.id);
    }
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* saveProjectSaga(action: SaveProjectActionType) {
  const { id, data, completed } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);
    const buyerService = new BuyerService();
    const buyerInteractor = new ManageBuyerInteractor(action.jwt, buyerService);
    if (data.new_buyer_title && data.new_buyer_title) {
      const buyer = yield buyerInteractor.createBuyer({
        title: data.new_buyer_title,
        submission_email: data.new_buyer_submission_email,
        submission_email_cc: data.new_buyer_submission_email_cc,
        author: data.author
      });
      data.buyer = buyer.id;
    }
    const project = yield interactor.updateProject(id, data);
    yield put(updateProjectAction(project));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

function* deleteProjectSaga(action: DeleteProjectActionType) {
  const { id, completed } = action;
  try {
    const service = new ProjectService();
    const interactor = new ManageProjectInteractor(action.jwt, service);
    yield interactor.deleteProject(id);
    yield put(removeProjectAction(id));
    if (completed) {
      completed();
    }
  } catch (err) {
    yield put(logError(err, 'Projects'));
  }
}

// Combined Sagas

export default [
  takeLatest(FETCH_ALL_PROJECTS, fetchAllProjectsSaga),
  takeLatest(SELECT_PROJECT, selectProjectSaga),
  takeEvery(SAVE_PROJECT_STATUS, saveProjectStatusSaga),
  takeEvery(SAVE_PROJECT_REQUEST_CASTING, saveProjectRequestCastingSaga),
  takeEvery(SAVE_PROJECT_REQUEST_APPROVAL, saveProjectRequestApprovalSaga),
  takeEvery(SAVE_PROJECT_APPROVED, saveProjectApprovedSaga),
  takeEvery(NEW_PROJECT, newProjectSaga),
  takeEvery(SAVE_PROJECT, saveProjectSaga),
  takeEvery(DELETE_PROJECT, deleteProjectSaga)
];
