import { ProjectServiceType } from '../../domain/services/project-service';
import fetch from 'isomorphic-unfetch';
import Project from '../../domain/models/project';
import User from '../../domain/models/user';
import { parseJsonRes } from '../../utils/parse-res';

const PAGE_COUNT = 200;

export class ProjectService implements ProjectServiceType {
  async getProjects(jwt: string, start?: number): Promise<Project[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/projects?_sort=created_at:DESC,title:ASC&_limit=${
        start ? start : 0 + PAGE_COUNT
      }&_start=${start ? start : 0}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const projects = resData.map((o) => new Project().fromJson(o));
    return projects;
  }

  async getProject(jwt: string, projectId: number): Promise<Project> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const project = new Project().fromJson(resData);
    return project;
  }

  async createProject(jwt: string, data: any): Promise<Project> {
    console.log('createProject', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_attachments) {
      for (let i = 0; i < data.new_attachments.length; i++) {
        const file = data.new_attachments[i];
        formData.append(`files.attachments`, file, file.name);
      }
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    return new Project().fromJson(resData);
  }

  async updateProject(jwt: string, projectId: number, data: any): Promise<Project> {
    console.log('updateProject', projectId, data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_attachments) {
      for (let i = 0; i < data.new_attachments.length; i++) {
        const file = data.new_attachments[i];
        formData.append(`files.attachments`, file, file.name);
      }
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/${projectId}`, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    return new Project().fromJson(resData);
  }

  async updateProjectStatus(jwt: string, projectId: number, status: string): Promise<Project> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/${projectId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ status })
    });
    const resData = await parseJsonRes(res);
    return new Project().fromJson(resData);
  }

  async sendManual(
    jwt: string,
    projectId: number,
    subject: string,
    title: string,
    body: string,
    email: string,
    message: string
  ): Promise<void> {
    const data = { projectId, subject, title, body, email, message };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/sendmanual`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    } else {
      throw new Error('Send email failed.');
    }
  }

  async send(
    jwt: string,
    projectId: number,
    subject: string,
    title: string,
    body: string,
    message: string
  ): Promise<void> {
    const data = { projectId, subject, title, body, message };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    } else {
      throw new Error('Send email failed.');
    }
  }

  async notifyRequestCasting(jwt: string, projectId: number): Promise<void> {
    const data = { projectId };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/notify-request-casting`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    } else {
      throw new Error('Send email failed.');
    }
  }

  async notifyRequestApproval(jwt: string, projectId: number): Promise<void> {
    const data = { projectId };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/notify-request-approval`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    } else {
      throw new Error('Send email failed.');
    }
  }

  async notifyApproved(jwt: string, projectId: number): Promise<void> {
    const data = { projectId };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/notify-approved`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    } else {
      throw new Error('Send email failed.');
    }
  }

  async getAgents(jwt: string): Promise<User[]> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/agents`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const users = resData.map((o) => new User().fromJson(o));
    return users;
  }

  async getProjectPackage(projectId: number): Promise<string | null> {
    console.log(`${process.env.NEXT_PUBLIC_API_URL}/projects/${projectId}/package`);
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/${projectId}/package`);
    if (res.ok) {
      return res.text();
    }
    return null;
  }

  async searchProjects(jwt: string, txt: string): Promise<Project[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/projects?title_contains=${txt}&_sort=title:ASC&_limit=20`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const projects = resData.map((o) => new Project().fromJson(o));
    return projects;
  }

  async deleteProject(jwt: string, id: number): Promise<void> {
    console.log('updateProject', id);
    const formData = new FormData();
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/projects/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    await parseJsonRes(res);
    return;
  }
}
