import { logError } from '../../../utils/log';
import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  addSelectedInvitationAction,
  removeSelectedInvitationAction,
  updateSelectedInvitationAction
} from '../actions/invitation-action';
import {
  incrementInvitationCountSelectedProjectRoleAction,
  decrementInvitationCountSelectedProjectRoleAction
} from '../actions/project-role-action';
import { InvitationService } from '../../../data/services/data-invitation-service';
import { ManageInvitationInteractor } from '../../../domain/usecases/manage-invitation-interactor';

// Constants

export const NEW_SELECTED_INVITATION = 'invitation/saga/selected/new';
export const DELETE_SELECTED_INVITATION = 'invitation/saga/selected/delete';
export const SAVE_SELECTED_INVITATION_IS_OVERSCALE = 'invitation/saga/selected/save/isoverscale';
export const SAVE_SELECTED_INVITATION_IS_REQUESTED = 'invitation/saga/selected/save/isrequested';

// Interfaces

interface NewInvitationForSelectedActionType {
  type: string;
  jwt: string;
  data: any;
}

interface DeleteInvitationForSelectedActionType {
  type: string;
  jwt: string;
  id: number;
}

interface SaveInvitationForSelectedIsOverscaleActionType {
  type: string;
  jwt: string;
  id: number;
  isOverscale: boolean;
}

interface SaveInvitationForSelectedIsRequestedActionType {
  type: string;
  jwt: string;
  id: number;
  isRequested: boolean;
}

// Actions

export const newInvitationForSelectedAction = (
  jwt: string,
  data: any
): NewInvitationForSelectedActionType => ({
  type: NEW_SELECTED_INVITATION,
  jwt,
  data
});

export const deleteInvitationForSelectedAction = (
  jwt: string,
  id: number
): DeleteInvitationForSelectedActionType => ({
  type: DELETE_SELECTED_INVITATION,
  jwt,
  id
});

export const saveInvitationForSelectedIsOverscaleAction = (
  jwt: string,
  id: number,
  isOverscale: boolean
): SaveInvitationForSelectedIsOverscaleActionType => ({
  type: SAVE_SELECTED_INVITATION_IS_OVERSCALE,
  jwt,
  id,
  isOverscale
});

export const saveInvitationForSelectedIsRequestedAction = (
  jwt: string,
  id: number,
  isRequested: boolean
): SaveInvitationForSelectedIsRequestedActionType => ({
  type: SAVE_SELECTED_INVITATION_IS_REQUESTED,
  jwt,
  id,
  isRequested
});

// Sagas

function* newInvitationForSelectedSaga(action: NewInvitationForSelectedActionType) {
  const { data } = action;
  try {
    const service = new InvitationService();
    const interactor = new ManageInvitationInteractor(action.jwt, service);

    const invitation = yield interactor.createInvitation(data);
    yield put(addSelectedInvitationAction(invitation));
    yield put(incrementInvitationCountSelectedProjectRoleAction());
  } catch (err) {
    yield put(logError(err, 'Invitations'));
  }
}

function* deleteInvitationForSelectedSaga(action: DeleteInvitationForSelectedActionType) {
  const { id } = action;
  try {
    const service = new InvitationService();
    const interactor = new ManageInvitationInteractor(action.jwt, service);

    yield interactor.deleteInvitation(id);
    yield put(removeSelectedInvitationAction(id));
    yield put(decrementInvitationCountSelectedProjectRoleAction());
  } catch (err) {
    yield put(logError(err, 'Invitations'));
  }
}

function* saveInvitationForSelectedIsOverscaleSaga(
  action: SaveInvitationForSelectedIsOverscaleActionType
) {
  const { id, isOverscale } = action;
  try {
    const service = new InvitationService();
    const interactor = new ManageInvitationInteractor(action.jwt, service);

    const invitation = yield interactor.updateInvitationIsOverscale(id, isOverscale);
    yield put(updateSelectedInvitationAction(invitation));
  } catch (err) {
    yield put(logError(err, 'Invitations'));
  }
}

function* saveInvitationForSelectedIsRequestedSaga(
  action: SaveInvitationForSelectedIsRequestedActionType
) {
  const { id, isRequested } = action;
  try {
    const service = new InvitationService();
    const interactor = new ManageInvitationInteractor(action.jwt, service);

    const invitation = yield interactor.updateInvitationIsRequested(id, isRequested);
    yield put(updateSelectedInvitationAction(invitation));
  } catch (err) {
    yield put(logError(err, 'Invitations'));
  }
}

// Combined Sagas

export default [
  takeEvery(NEW_SELECTED_INVITATION, newInvitationForSelectedSaga),
  takeEvery(DELETE_SELECTED_INVITATION, deleteInvitationForSelectedSaga),
  takeEvery(SAVE_SELECTED_INVITATION_IS_OVERSCALE, saveInvitationForSelectedIsOverscaleSaga),
  takeEvery(SAVE_SELECTED_INVITATION_IS_REQUESTED, saveInvitationForSelectedIsRequestedSaga)
];
