import { initialState, StateType } from "../state";
import Buyer from "../../../domain/models/buyer";

type StateSlice = StateType["buyer"];

// Constants

const UPDATE_BUYERS = "buyer/all/update";
const ADD_BUYER = "buyer/add";
const UPDATE_BUYER = "buyer/update";
const UPDATE_SELECTED_BUYER = "buyer/select";

// Selectors

export const selectedBuyerSelector = (state: StateType) =>
  state.buyer?.selectedId ? state.buyer.byId[state.buyer.selectedId] : null;

export const buyersSelector = (state: StateType) =>
  state.buyer?.allIds?.map((id) => state.buyer.byId[id]);

// Interfaces

export interface UpdateBuyersActionType {
  type: string;
  buyers: Buyer[] | null;
}

export interface AddBuyerActionType {
  type: string;
  buyer: Buyer | null;
}

export interface UpdateBuyerActionType {
  type: string;
  buyer: Buyer | null;
}

export interface UpdateSelectedBuyerActionType {
  type: string;
  buyer: Buyer | null;
}

// Actions

export const updateBuyersAction = (
  buyers: Buyer[] | null
): UpdateBuyersActionType => ({
  type: UPDATE_BUYERS,
  buyers,
});

export const addBuyerAction = (buyer: Buyer): UpdateBuyerActionType => ({
  type: UPDATE_BUYER,
  buyer,
});

export const updateBuyerAction = (buyer: Buyer): UpdateBuyerActionType => ({
  type: UPDATE_BUYER,
  buyer,
});

export const updateSelectedBuyerAction = (
  buyer: Buyer
): UpdateSelectedBuyerActionType => ({
  type: UPDATE_SELECTED_BUYER,
  buyer,
});

// Handlers

const updateAllHandler = (
  state: StateSlice,
  action: UpdateBuyersActionType
): StateType["buyer"] => {
  const buyers = action.buyers;
  if (buyers) {
    const byId = state?.byId ? { ...state.byId } : {};
    const allIds = buyers.map((o) => o.id);
    buyers.forEach((buyer) => {
      byId[buyer.id] = buyer;
    });
    return { ...state, byId, allIds };
  }
  return { ...state, allIds: null };
};

const addHandler = (
  state: StateSlice,
  action: AddBuyerActionType
): StateType["buyer"] => {
  const buyer = action.buyer;
  const byId = state?.byId ? { ...state.byId } : {};
  const allIds = state?.allIds ? [...state.allIds] : [];
  allIds.unshift(buyer.id);
  byId[buyer.id] = buyer;
  return { ...state, byId, allIds };
};

const updateHandler = (
  state: StateSlice,
  action: UpdateBuyerActionType
): StateType["buyer"] => {
  const buyer = action.buyer;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[buyer.id]) {
    byId[buyer.id] = buyer;
  }
  return { ...state, byId };
};

const updateSelectedHandler = (
  state: StateSlice,
  action: UpdateSelectedBuyerActionType
): StateType["buyer"] => {
  if (action.buyer) {
    const buyer = action.buyer;
    const byId = state?.byId ? { ...state.byId } : {};
    byId[buyer.id] = buyer;
    return { ...state, byId, selectedId: buyer.id };
  }
  return { ...state, selectedId: null };
};

// Reducer

export const buyerReducer = (
  state: StateSlice = initialState.buyer,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_BUYERS:
      return updateAllHandler(state, action);
    case ADD_BUYER:
      return addHandler(state, action);
    case UPDATE_BUYER:
      return updateHandler(state, action);
    case UPDATE_SELECTED_BUYER:
      return updateSelectedHandler(state, action);
    default:
      return state;
  }
};
