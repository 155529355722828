import Buyer from '../../domain/models/buyer';
import Client from '../../domain/models/client';
import Config from '../../domain/models/config';
import Invitation from '../../domain/models/invitation';
import ProjectRole from '../../domain/models/project-role';
import Project from '../../domain/models/project';
import PropertyList from '../../domain/models/property-list';
import Submission from '../../domain/models/submission';

export interface BuyerStateType {
  readonly byId: { [key: number]: Buyer } | null;
  readonly allIds: number[] | null;
  readonly selectedId: number | null;
}

export interface ClientStateType {
  readonly byId: { [key: number]: Client } | null;
  readonly allIds: number[] | null;
  readonly selectedId: number | null;
}

export interface ConfigStateType {
  readonly config: Config;
}

export interface InvitationStateType {
  readonly byId: { [key: number]: Invitation } | null;
  readonly selectedIds: number[] | null;
}

export interface PlatformStateType {
  readonly snackbar: {
    successSnackbarOpen: boolean;
    successSnackbarMessage: string;
    errorSnackbarOpen: boolean;
    errorSnackbarMessage: string;
    infoSnackbarOpen: boolean;
    infoSnackbarMessage: string;
  };
}

export interface ProjectRoleStateType {
  readonly byId: { [key: number]: ProjectRole } | null;
  readonly selectedId: number | null;
}

export interface ProjectStateType {
  readonly byId: { [key: number]: Project } | null;
  readonly allIds: number[] | null;
  readonly selectedId: number | null;
}

export interface PropertyListStateType {
  readonly propertyLists: PropertyList[] | null;
}

export interface SubmissionStateType {
  readonly byId: { [key: number]: Submission } | null;
  readonly selectedIds: number[] | null;
  readonly editSubmissionId: number | null;
}

export interface StateType {
  readonly buyer: BuyerStateType | null;
  readonly client: ClientStateType | null;
  readonly config: ConfigStateType | null;
  readonly invitation: InvitationStateType | null;
  readonly platform: PlatformStateType | null;
  readonly projectRole: ProjectRoleStateType | null;
  readonly project: ProjectStateType | null;
  readonly propertyList: PropertyListStateType | null;
  readonly submission: SubmissionStateType | null;
}

export const initialState: StateType = {
  buyer: null,
  client: null,
  config: null,
  invitation: null,
  platform: null,
  projectRole: null,
  project: null,
  propertyList: null,
  submission: null
};
