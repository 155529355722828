import React, { createContext, useState, useEffect } from "react";

type PlayerContextType = {
  currentTrack,
  loadTrack: (track: any) => void,
  loadTracks: (tracks: any, id: number) => void,
  nextTrack: () => void,
  resetCurrentTrack: () => void
}

export const playerContext = createContext<PlayerContextType>(null);

const PlayerProvider: React.FC = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [tracks, setTracks] = useState(null);

  const loadTrack = (track) => {
    setCurrentTrack(track);
  };

  const loadTracks = (tracks, id) => {
    setTracks(tracks);
    const track = tracks.find((t) => t.id == id);
    setCurrentTrack(track);
  };

  const nextTrack = () => {
    if (tracks) {
      const currentTrackIndex = tracks.findIndex(
        (t) => t.id == currentTrack.id
      );
      const nextTrack = tracks[currentTrackIndex + 1];
      if (nextTrack) {
        setCurrentTrack(nextTrack);
        return;
      }
    }
    setCurrentTrack(null);
  };

  const resetCurrentTrack = () => {
    setTracks(null);
    setCurrentTrack(null);
  };

  const value = {
    currentTrack,
    loadTrack,
    loadTracks,
    nextTrack,
    resetCurrentTrack,
  };

  return <playerContext.Provider value={value}>
    {children || null}
  </playerContext.Provider>;
};

export default PlayerProvider
