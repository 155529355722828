const colors = {
  text: "#414141",
  background: "#ffffff",
  primary: "#f9671e",
  primaryText: "#ffffff",
  secondary: "#3c3c3c",
  secondaryText: "#ffffff",
  success: "#4BB543",
  successText: "#ffffff",
  warning: "#ffcc00",
  warningText: "#000000",
  danger: "#F32013",
  dangerText: "#ffffff",
};

export default { colors };
