class Media {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  ext: string;
  mime: string;
  url: string;
  thumbnailUrl: string;
  thumbnailLargeUrl: string;

  fromJson(data): Media {
    this.id = data.id;
    this.name = data.name;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.ext = data.ext;
    this.mime = data.mime;

    let thumbUrl = data.url;
    let thumbLargeUrl = data.url;
    if (data.formats && data.formats.thumbnail) {
      thumbUrl = data.formats.thumbnail.url;
    }
    if (data.formats && data.formats.large) {
      thumbLargeUrl = data.formats.large.url;
    }
    // fallback
    if (data.formats && !data.formats.thumbnail && data.formats.small) {
      thumbUrl = data.formats.small.url;
    }

    if (process.env.NODE_ENV == 'development' && data.url && data.url.startsWith('/')) {
      data.url = `${process.env.NEXT_PUBLIC_API_URL}${data.url}`;
      data.thumbnailUrl = `${process.env.NEXT_PUBLIC_API_URL}${thumbUrl}`;
      data.thumbnailLargeUrl = `${process.env.NEXT_PUBLIC_API_URL}${thumbLargeUrl}`;
    }
    if (
      process.env.NODE_ENV == 'production' &&
      data.url &&
      data.url.startsWith('https://s3.us-west-2.amazonaws.com/')
    ) {
      data.url = data.url.replace('s3.us-west-2.amazonaws.com/', '');
      data.thumbnailUrl = thumbUrl.replace('s3.us-west-2.amazonaws.com/', '');
      data.thumbnailLargeUrl = thumbLargeUrl.replace('s3.us-west-2.amazonaws.com/', '');
    }
    this.url = data.url;
    this.thumbnailUrl = thumbUrl;
    this.thumbnailLargeUrl = thumbLargeUrl;
    return this;
  }
}

export default Media;
