import { ClientServiceType } from '../../domain/services/client-service';
import fetch from 'isomorphic-unfetch';
import Client from '../../domain/models/client';
import ClientDocument from '../../domain/models/client-document';
import { parseJsonRes } from '../../utils/parse-res';

const PAGE_COUNT = 200;

export class ClientService implements ClientServiceType {
  async filterClients(jwt: string, filter: any, txt: string, start: number): Promise<Client[]> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/filter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(
        txt && txt.length > 0
          ? { ...filter, ...{ search: txt }, ...{ _limit: start + PAGE_COUNT, _start: start } }
          : { ...filter, ...{ _limit: start + PAGE_COUNT, _start: start } }
      )
    });
    if (res.ok) {
      const resData = await parseJsonRes(res);
      const clients = resData.map((o) => new Client().fromJson(o));
      return clients;
    }
    return [];
  }

  async getClients(jwt: string): Promise<Client[]> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients?_sort=name:ASC`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const clients = resData.map((o) => new Client().fromJson(o));
    return clients;
  }

  async getClient(jwt: string, clientId: number): Promise<Client> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/${clientId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const client = new Client().fromJson(resData);
    return client;
  }

  async createClient(jwt: string, data: any): Promise<Client> {
    console.log('createClient', data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_media_demo) {
      for (let i = 0; i < data.new_media_demo.length; i++) {
        const file = data.new_media_demo[i];
        formData.append(`files.media_demo`, file, file.name);
      }
    }
    if (data.new_media_profile) {
      const file = data.new_media_profile;
      formData.append(`files.media_profile`, file, file.name);
    }
    if (data.new_video_demo) {
      const file = data.new_video_demo;
      formData.append(`files.video_demo`, file, file.name);
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Client().fromJson(resData);
  }

  async updateClient(jwt: string, clientId: number, data: any): Promise<Client> {
    console.log('updateClient', clientId, data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_media_demo) {
      for (let i = 0; i < data.new_media_demo.length; i++) {
        const file = data.new_media_demo[i];
        formData.append(`files.media_demo`, file, file.name);
      }
    }
    if (data.new_media_profile) {
      const file = data.new_media_profile;
      formData.append(`files.media_profile`, file, file.name);
    }
    if (data.new_video_demo) {
      const file = data.new_video_demo;
      formData.append(`files.video_demo`, file, file.name);
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/${clientId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Client().fromJson(resData);
  }

  async deleteClient(jwt: string, clientId: number): Promise<Client> {
    console.log('deleteClient', clientId);
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/${clientId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    return new Client().fromJson(resData);
  }

  async updateClientBio(clientId: number, data: any): Promise<Client> {
    console.log('updateClientBio', clientId, data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (data.new_media_demo) {
      for (let i = 0; i < data.new_media_demo.length; i++) {
        const file = data.new_media_demo[i];
        formData.append(`files.media_demo`, file, file.name);
      }
    }
    if (data.new_media_profile) {
      const file = data.new_media_profile;
      formData.append(`files.media_profile`, file, file.name);
    }
    if (data.new_video_demo) {
      const file = data.new_video_demo;
      formData.append(`files.video_demo`, file, file.name);
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/${clientId}/bio`, {
      method: 'PUT',
      body: formData
    });
    const resData = await parseJsonRes(res);
    return new Client().fromJson(resData);
  }

  async send(
    jwt: string,
    ids: number[],
    subject: string,
    title: string,
    body: string
  ): Promise<void> {
    const data = { ids, subject, title, body };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    }
    throw new Error('Send email failed.');
  }

  async sendProjectRoleMessage(
    jwt: string,
    id: number,
    subject: string,
    title: string,
    body: string,
    project_role: number
  ): Promise<void> {
    const data = { id, subject, title, body, project_role };
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/sendprojectrolemessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(data)
    });
    if (res.ok) {
      return;
    }
    throw new Error('Send email failed.');
  }

  async getClientByUnique(unique: string): Promise<Client> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/unique/${unique}`);
    const resData = await parseJsonRes(res);
    return new Client().fromJson(resData);
  }

  async getClientForClient(jwt: string, clientId: number): Promise<Client> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/clients/client/${clientId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const resData = await parseJsonRes(res);
    const client = new Client().fromJson(resData);
    return client;
  }

  async searchClients(jwt: string, txt: string): Promise<Client[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/clients?name_contains=${txt}&_sort=name:ASC&_limit=20`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const clients = resData.map((o) => new Client().fromJson(o));
    return clients;
  }

  async getClientDocument(jwt: string, clientId: number, year: number): Promise<ClientDocument> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/clients/${clientId}/documents/${year}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    const clientDocument = new ClientDocument().fromJson(resData);
    return clientDocument;
  }

  appendFiles(formData: FormData, files: File[], name: string) {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append(`files.${name}`, file, file.name);
      }
    }
  }

  async uploadClientDocument(jwt: string, clientId: number, data: any): Promise<boolean> {
    console.log('uploadClientDocument', clientId, data);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    this.appendFiles(formData, data.new_passport, 'new_passport');
    this.appendFiles(formData, data.new_drivers_license, 'new_drivers_license');
    this.appendFiles(formData, data.new_social_security, 'new_social_security');
    this.appendFiles(formData, data.new_vox_authorization, 'new_vox_authorization');
    this.appendFiles(formData, data.new_vox_direct_deposit, 'new_vox_direct_deposit');
    this.appendFiles(formData, data.new_i9, 'new_i9');
    this.appendFiles(formData, data.new_w4, 'new_w4');
    this.appendFiles(formData, data.new_de4, 'new_de4');
    this.appendFiles(formData, data.new_w9, 'new_w9');
    this.appendFiles(formData, data.new_article_incorporation, 'new_article_incorporation');
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/clients/${clientId}/documents/${data.year}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    );
    const resData = await parseJsonRes(res);
    return resData.success;
  }
}
