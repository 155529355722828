import { initialState, StateType } from '../state';
import Project from '../../../domain/models/project';

type StateSlice = StateType['project'];

// Constants

const UPDATE_PROJECTS = 'project/all/update';
const ADD_PROJECT = 'project/add';
const UPDATE_PROJECT = 'project/update';
const UPDATE_SELECTED_PROJECT = 'project/select';
const REMOVE_PROJECT = 'project/remove';

// Selectors

export const selectedProjectSelector = (state: StateType) =>
  state.project?.selectedId ? state.project.byId[state.project.selectedId] : null;

export const draftProjectsSelector = (state: StateType) =>
  state.project?.allIds?.map((id) => state.project.byId[id]).filter((p) => p?.status == 'draft');

export const castingProjectsSelector = (state: StateType) =>
  state.project?.allIds?.map((id) => state.project.byId[id]).filter((p) => p?.status == 'casting');

export const pendingProjectsSelector = (state: StateType) =>
  state.project?.allIds?.map((id) => state.project.byId[id]).filter((p) => p?.status == 'pending');

export const openProjectsSelector = (state: StateType) =>
  state.project?.allIds?.map((id) => state.project.byId[id]).filter((p) => p?.status == 'open');

export const submittedProjectsSelector = (state: StateType) =>
  state.project?.allIds
    ?.map((id) => state.project.byId[id])
    .filter((p) => p?.status == 'submitted' || p?.status == 'read');

export const completedProjectsSelector = (state: StateType) =>
  state.project?.allIds
    ?.map((id) => state.project.byId[id])
    .filter((p) => p?.status == 'completed');

export const cancelledProjectsSelector = (state: StateType) =>
  state.project?.allIds
    ?.map((id) => state.project.byId[id])
    .filter((p) => p?.status == 'cancelled');

export const projectRolesWithPendingSubmissionsSelector = (state: StateType) => {
  const projectRoles = [];
  const openProjects = state.project?.allIds
    ?.map((id) => state.project.byId[id])
    .filter((p) => p?.status == 'open');
  if (openProjects && openProjects.length > 0) {
    openProjects.forEach((p) => {
      if (p.project_roles && p.project_roles.length > 0) {
        p.project_roles.forEach((r) => {
          if (r.total_submissions_pending > 0) {
            r.project = p;
            projectRoles.push(r);
          }
        });
      }
    });
  }
  return projectRoles;
};

export const projectRolesWithPendingSubmissionsCountSelector = (state: StateType) => {
  let count = 0;
  const openProjects = state.project?.allIds
    ?.map((id) => state.project.byId[id])
    .filter((p) => p?.status == 'open');
  if (openProjects && openProjects.length > 0) {
    openProjects.forEach((p) => {
      if (p.project_roles && p.project_roles.length > 0) {
        p.project_roles.forEach((r) => {
          if (r.total_submissions_pending > 0) {
            count += r.total_submissions_pending;
          }
        });
      }
    });
  }
  return count;
};

// Interfaces

export interface UpdateProjectsActionType {
  type: string;
  projects: Project[] | null;
}

export interface AddProjectActionType {
  type: string;
  project: Project | null;
}

export interface UpdateProjectActionType {
  type: string;
  project: Project | null;
}

export interface UpdateSelectedProjectActionType {
  type: string;
  project: Project | null;
}

export interface RemoveProjectActionType {
  type: string;
  id: number;
}

// Actions

export const updateProjectsAction = (projects: Project[] | null): UpdateProjectsActionType => ({
  type: UPDATE_PROJECTS,
  projects
});

export const addProjectAction = (project: Project): UpdateProjectActionType => ({
  type: UPDATE_PROJECT,
  project
});

export const updateProjectAction = (project: Project): UpdateProjectActionType => ({
  type: UPDATE_PROJECT,
  project
});

export const updateSelectedProjectAction = (project: Project): UpdateSelectedProjectActionType => ({
  type: UPDATE_SELECTED_PROJECT,
  project
});

export const removeProjectAction = (id: number): RemoveProjectActionType => ({
  type: REMOVE_PROJECT,
  id
});

// Handlers

const updateAllHandler = (
  state: StateSlice,
  action: UpdateProjectsActionType
): StateType['project'] => {
  const projects = action.projects;
  if (projects) {
    const byId = state?.byId ? { ...state.byId } : {};
    const allIds = projects.map((o) => o.id);
    projects.forEach((project) => {
      byId[project.id] = project;
    });
    return { ...state, byId, allIds };
  }
  return { ...state, allIds: null };
};

const addHandler = (state: StateSlice, action: AddProjectActionType): StateType['project'] => {
  const project = action.project;
  const byId = state?.byId ? { ...state.byId } : {};
  const allIds = state?.allIds ? [...state.allIds] : [];
  allIds.unshift(project.id);
  byId[project.id] = project;
  return { ...state, byId, allIds };
};

const updateHandler = (
  state: StateSlice,
  action: UpdateProjectActionType
): StateType['project'] => {
  const project = action.project;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[project.id]) {
    byId[project.id] = project;
  }
  return { ...state, byId };
};

const updateSelectedHandler = (
  state: StateSlice,
  action: UpdateSelectedProjectActionType
): StateType['project'] => {
  if (action.project) {
    const project = action.project;
    const byId = state?.byId ? { ...state.byId } : {};
    byId[project.id] = project;
    return { ...state, byId, selectedId: project.id };
  }
  return { ...state, selectedId: null };
};

const removeHandler = (
  state: StateSlice,
  action: RemoveProjectActionType
): StateType['project'] => {
  const id = action.id;
  const byId = state?.byId ? { ...state.byId } : {};
  if (byId[id]) {
    delete byId[id];
  }
  return { ...state, byId };
};

// Reducer

export const projectReducer = (
  state: StateSlice = initialState.project,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_PROJECTS:
      return updateAllHandler(state, action);
    case ADD_PROJECT:
      return addHandler(state, action);
    case UPDATE_PROJECT:
      return updateHandler(state, action);
    case UPDATE_SELECTED_PROJECT:
      return updateSelectedHandler(state, action);
    case REMOVE_PROJECT:
      return removeHandler(state, action);
    default:
      return state;
  }
};
